import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FaqComponent} from './Faq.component';
import { ComponentsModule } from '../components.module';
import { FaqsService } from '../../services/faq.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
    ],
    declarations: [
        FaqComponent,
    ],
    exports: [
        FaqComponent
    ],

})
export class FaqModule {}
