// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,

  backend: "http://api.reshaoc.org/api",
  base_url_backend: "http://api.reshaoc.org",
  accet_url: "http://api.reshaoc.org/storage/app/public",
  backendOffice: "https://backoffice.reshaoc.org/",

  // backend: "http://127.0.0.1:8000/api",
  // base_url_backend: "http://127.0.0.1:8000",
  // accet_url: "http://127.0.0.1:8000/storage",
  // backendOffice: "http://localhost:4000/",
  


  // backend: "https://reshaoc.africanschoolofeconomics.com/api",



  expirationTime: 0,


  api_url: "#" ,
  ssoUrlCallback: "#",
  // "api/liste/acteurs",

  api_token: "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxOSIsImlhdCI6MTY4MjkzMTUyMiwiZXhwIjoxNzMzOTY3MjYzfQ.NK_oSQli45TosrHkl5gHt8kqwkoImF4_7NIWoEr5sExpBY3lf7AchhDDPjufueUCPrCtgP7hT1SOf_OxM-J1Bg",
  // frontend: "https://toursime-app-api.herokuapp.com/"
  frontend: "#",

};
