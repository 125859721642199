import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import {AdherantComponent} from './components/espace-adherant/adherant.component';
import { AccueilComponent } from './accueil';
import {AdminComponent} from './admin/admin.component';
import {ReshaocComponent} from './reshaoc';
import {ActualitesComponent} from './actualites';
import {AdhereComponent} from './components/Adherer';
import {ShowActualitesComponent} from './show-actualites';
import {ReserverComponent} from './components/Reserver';
import {PaysMembreComponent} from './pays-membre';
import {ContactComponent} from './contact';
import {DocumenthequeComponent} from './documentheque';
import {PayerCotisationComponent} from './payer-cotisation';
import {AdherantPayerCotisationComponent} from './components/Adherer-payer-cotisation';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'admin', redirectTo: '/admin', pathMatch: 'full' },
    { path: 'admin',component: AdminComponent },
    { path: 'reshaoc/:type',         component: ReshaocComponent },
    { path: 'home',         component: AccueilComponent },
    { path: 'adherer',         component: AdhereComponent },
    { path: 'pays-membre',         component: PaysMembreComponent },
    { path: 'actualites/:type',         component: ActualitesComponent },
    { path: 'show-actualites/:id',         component: ShowActualitesComponent },
    { path: 'reservation/:id',         component: ReserverComponent },
    { path: 'adherant',         component: AdherantComponent },
    { path: 'contact',         component: ContactComponent },

    { path: 'documentheque/:type',         component: DocumenthequeComponent },

    { path: 'payer-cotisation/:id',         component: AdherantPayerCotisationComponent },

    // // { path: 'reclamation',         component: PlainteComponent },
    // { path: 'concours',         component: ConcoursComponent },
    // { path: 'concours/:id',         component: DetailconcourComponent },
    // { path: 'detailReglementation/:id',         component: DetailReglementationComponent },
    // { path: 'commentObtenir',         component: CommentObtenirComponent },
    // { path: 'operateurTouristique',         component: AdherantComponent },
    // // { path: 'detailHebergement',         component: DetailHebergementComponent },
    // { path: 'detailHebergement/:id',         component: DetailHebergementComponent },
    // { path: 'listeHebergement',         component: ListeHebergementComponent },
    // { path: 'listeAgences',         component: ListeAgencesComponent},
    // { path: 'detailAgence/:id',         component: DetailAgenceComponent },
    // { path: 'listeGuides',         component: ListeGuidesComponent },
    // { path: 'detailGuide/:id',         component: DetailGuideComponent },
    // { path: 'listeRestaurants',         component: ListeRestaurantsComponent },
    // { path: 'detailRestaurant/:id',         component: DetailRestaurantComponent },
    // { path: 'signup',           component: SignupComponent },
    // { path: 'landing',          component: LandingComponent },
    // { path: 'nucleoicons',      component: NucleoiconsComponent },
    // { path: 'evaluation', component: HotelComponent},
    // { path: 'espaceInvestiseur', component:  EspaceInvestisseurComponent},
    // { path: 'reclamation', component:  ReclamationComponent,canActivate :[AuthGuard]},
    // { path: 'register', component:  ReserverComponent },
    // {path: 'admin/sso_callback',component : SsocallbackComponent },
    // {path: 'admin',component : AdminComponent },





    // {
    //   path: 'home',
    //   component: AccueilComponent,
    //   children: [{
    //     path: '',
    //     loadChildren: () => import('./accueil/accueil.module').then(m => m.AccueilModule)
    //   }]
    // }
];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
