import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdherantServiceService {

  url: string = environment.api_url;
  url_back: string = environment.backend;
  token: string = environment.api_token;
  url_op: string = environment.backend  ;

  headers = new HttpHeaders({
    // 'Content-type' : 'application/json',
    // 'Bearer_token' : '3|KdPLUKMqyTqMBE30M8rHsV1FXcNjVvzNNXzQJqmV',
    // 'Content-Type' : 'application/json',
    // 'Accept'       : 'application/json',
    'Authorization': `Bearer ${this.token}`
  });


  constructor(private http: HttpClient) { }


  getActeurByIfu(data:any): Observable<Object> {
   
    return this.http.post(`${this.url}api/verifier/ifu`, data, {headers: this.headers});
  }

  getActeurById(id:number): Observable<Object> {
   
    return this.http.get(`${this.url_op}/getOpById/${id}`,{headers: this.headers});

  }

  getActeurByOmtId(id:number): Observable<Object> {
   
    return this.http.get(`${this.url_op}/getByOmtId/${id}`,{headers: this.headers});

  }

  addActeur(data:any): Observable<Object> {

    return this.http.post(`${this.url_back}/user/saveOperator`, data, {headers: this.headers});

  }

  login(data:any): Observable<Object> {

    return this.http.post(`${this.url_back}/adherant-login`, data,{headers: this.headers});

  }

  changePass(data:any,id:number): Observable<Object> {

    return this.http.post(`${this.url_back}/operateur_touristique/updatePassword/${id}`, data, {headers: this.headers});

  }

  updateProfile(data:any,id:number): Observable<Object> {

    return this.http.post(`${this.url_back}/operateur_touristique/update/${id}`, data, {headers: this.headers});

  }


}
