import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { OperateurTouristique } from 'app/models/operateur-touristique';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  url: string = environment.backend + '/operateur_touristique/creat'; 
  url_op: string = environment.backend + '/operateur_touristique'; 
  url_checking: string = environment.backend + '/operateur_touristique/ckeckExite'; 
  rccm_checking_url = environment.backend + '/rccm/search'; 
  npi_checking_url =environment.backend + '/npi/search';
  department_url = environment.backend + '/departement'; 
  commune_url = environment.backend + '/commune/list'
  constructor(private http: HttpClient) { }


    token: string = environment.api_token;
    headers = new HttpHeaders({
        'Authorization': `Bearer ${this.token}`
    });


  // Get département
  get_department(): Observable<Object> {
    return this.http.get(`${this.department_url}/list`, {headers: this.headers});
  }

  get_all_commune(): Observable<Object> {
    return this.http.get(`${this.commune_url}`, {headers: this.headers});
  }

  get_commune(id: String): Observable<Object> {
    return this.http.get(`${this.commune_url}/${id}`, {headers: this.headers});
  }
  // Register new tourisme operateur
  register(op: OperateurTouristique,job:number): Observable<Object> {
    return this.http.post(`${this.url}/${job}`, op, {headers: this.headers});
  }

  // : Observable<Object>
  verifyRCCM(rccm: String) {
  // return this.http.get(`${this.rccm_checking_url}/${rccm}`);

    if(rccm=='1234567890'){
     const result ={
        success : true,
        data : {
          raison_sociale:'GMAP',
          job: 'Guide Touristique',
          ifu: '1234567891011',
          email: 'infogmap@gmail.com',
          site_web:'http://tourisme.bj',
          telephone:'60000000',
          departement:'Littoral',
          commune:'Cotonou',
          adresse:'ggdt',
          // nom_promoteur:'TEST',
          // prenom_promoteur:'Test User',
          // sexe_promoteur:'FEMININ',
          // nationalite_promoteur:'Béninois'
        },
        message: 'RCCM retrieve succesfully'
      }
      return JSON.stringify(result);
    }
    else{
      const result ={
        success : false,
        data : {},
        message: 'Unknow RCCM '
      }
      return JSON.stringify(result);
    }
    
  }

  //  async verifyNPI(npi: String) : Promise<Object> {
  //   const res: any = await (this.http.post(`${this.npi_checking_url}`, {str: npi})).toPromise();
  //     return res && res.data.length > 0
  // }

  verifyNPI(npi: String) : Observable<Object> {
      return  this.http.post(`${this.npi_checking_url}`, {str: npi}, {headers: this.headers});
  }

    compareCode(npi: String,code:String) : Observable<Object> {
      return this.http.get(`${this.url_op}/getNpiVerifaction/${npi}`, {headers: this.headers});
    }

    ckeckExite(data:any) {
      if (data['champ'] == 'rccm') {
        const res: any = this.searchRccm(data['value'])
        if (res && res.data.length > 0) {
          return res.data[0]
        }
        else return null
      }
      return this.http.post(`${this.url_checking}`,data, {headers: this.headers});
     }

     search(search): Observable<Object> {
      return this.http.post(`${this.url}`, {search}, {headers: this.headers});
    }

    async searchRccm(search): Promise<Object> {
      const res: any = await (this.http.post(`${this.rccm_checking_url}`, {str: search}, {headers: this.headers})).toPromise();

      // return res && res.data.length > 0
      return res
    }

    checkNpi(search): Observable<Object> {
      return this.http.post(`${this.url}`, {search}, {headers: this.headers});
    }

  getStatOpByJob(){
    return this.http.get(`${this.url_op}/getStatistiqeOp/`,{headers: this.headers});
  }



}
