import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';

import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {HttpErrorResponse} from '@angular/common/http';
import {ReglementationsService} from '../services/reglementations.service';
import {Reglementation} from '../models/Reglementation';
import {ReshaocService} from '../services/reshaoc.service';
import {environment} from '../../environments/environment';
import {ParamettreImageService} from '../services/paramettre-image.service';


@Pipe({
    name: 'sanitizeHtml'
})
@Component({
    selector: 'detailReglementation',
    templateUrl: './reshaoc.component.html',
    styleUrls: ['./reshaoc.component.styl']
})
export class ReshaocComponent   implements  OnInit,PipeTransform {

    display = "none";
    pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

    reshaoc:any
    type:any
    titre
  loading_elemnt=true
    imageBaniere="default.jpg"
    listActualite:any;

    url: string = environment.accet_url
    constructor(private paramettreImageService:ParamettreImageService,private reshaocService: ReshaocService,private activatedRoute: ActivatedRoute,private _sanitizer:DomSanitizer,private location: Location) {

    }

    onCloseHandled() {
        this.display = "none";
    }


    transform(v:string):SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(v);
    }


    reglementation:Reglementation;



    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.type=params['type'];
           this. getLastReshaoc()
            // this.reglementation.push(new Reglementation(1,"Comment acheter un serveur","bassarou","bassarou","bassarou"));
        })

    }


    getImageBaniere(type): void {
        this.loading_elemnt=true
        this.paramettreImageService.getImageByType(type).subscribe((data: any) => {
                this.imageBaniere= data['data'].image;
                console.log( this.imageBaniere)
            this.loading_elemnt=false

            }, (error: HttpErrorResponse) => {
                console.log("Error while retrieving data");
            }
        )
    }


    getLastReshaoc() {

        this.loading_elemnt=true
        this.reshaocService.getReshaoc().subscribe((data: any) => {

            console.log(data['data'])

            // this.reshaoc=data['data']

            switch (this.type){
                case "presentation":
                    this.titre="Présentation du RESHAOC"
                    this.reshaoc=data['data'].presentation;
                    this.getImageBaniere("PRESENTATION-RESHAOC")
                    break;

                case "mission":
                    this.titre="Mission du RESHAOC"
                    this.reshaoc=data['data'].mission;
                    this.getImageBaniere("MISSIONS")
                    break;
                case "objectif":
                    this.titre="Objectif du RESHAOC"
                    this.reshaoc=data['data'].objectif;
                    this.getImageBaniere("OBJECTIFS")

                    break;
                case "organisation":
                    this.titre="Organisation du RESHAOC"
                    this.reshaoc=data['data'].organisation;
                    this.getImageBaniere("ORGANISATION")
                    break;

                case "plan":
                    this.titre="Plan stratégique RESHAOC"
                    this.reshaoc=data['data'].plan;
                    this.getImageBaniere("PLAN")
                    break;

            }

            this.loading_elemnt=false

            // this.editorPresention.root.innerHTML=data['data'].presentation
            // this.editorMission.root.innerHTML=data['data'].mission
            // this.editorObjectif.root.innerHTML=data['data'].objectif
            // this.editorOrganisation.root.innerHTML=data['data'].organisation
            // this.editorPlan.root.innerHTML=data['data'].plan

        }, (error: HttpErrorResponse) => {
            console.log("Error while retrieving data");
        });
    }

    back(): void {
      this.location.back()
    }
 
    view_file(url){
        
        console.log(url)
        this.display = "block";
    }
}
