import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Reclamation} from '../models/reclamation';
import {Observable} from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class ReclamationsService {

  url: string = environment.backend + '/complaints';
  url_by_job = environment.backend+ '/job/';
  url_by_user = environment.backend+ '/complaintsByEmail';
 

  constructor(private http: HttpClient) { }
  token: string = environment.api_token;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`
  });


  // // Enregistrement des reglementation
  // save(reclamation: Reclamation,id_job:number): Observable<Object> {
  //   return this.http.post(`${this.url_by_job}${id_job}/complaints`, reclamation);
  // }

  // Enregistrement des reglementation
  save(data: any,id_job:number): Observable<Object> {
    return this.http.post(`${this.url_by_job}${id_job}/complaints`, data,{headers: this.headers});
  }

  // liste des Accord
  list(): Observable<Object> {
    return this.http.get(`${this.url}`,{headers: this.headers});
  }

  listByUser(user_mail:string): Observable<Object> {
    return this.http.get(`${this.url_by_user}/${user_mail}`,{headers: this.headers});
  }
 
  // liste des IDE par id
  getById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/${id}`,{headers: this.headers});
  }


}
