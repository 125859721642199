import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import { ComponentsModule } from '../components.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {AdherantPayerCotisationComponent} from './adherant-payer-cotisation.component';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import {Ng2TelInputModule} from 'ng2-tel-input';


const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        Ng2TelInputModule,
        NgWizardModule.forRoot(ngWizardConfig)
    ],
    declarations: [
        AdherantPayerCotisationComponent,
    ],
    exports: [
        AdherantPayerCotisationComponent
    ],

})
export class AdherantPayerCotisationModule {}
