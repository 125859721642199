/** @format */

import { Component, OnInit, Pipe, PipeTransform } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { HttpErrorResponse } from "@angular/common/http";
import { ReglementationsService } from "../services/reglementations.service";
import { Reglementation } from "../models/Reglementation";
import { ReshaocService } from "../services/reshaoc.service";
import { ActualiteService } from "../services/actualite.service";
import { environment } from "../../environments/environment";
import { ParamettreImageService } from "../services/paramettre-image.service";

@Pipe({
  name: "sanitizeHtml",
})
@Component({
  selector: "detailReglementation",
  templateUrl: "./actualites.component.html",
  styleUrls: ["./actualites.component.styl"],
})
export class ActualitesComponent implements OnInit, PipeTransform {
  display = "none";
  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

  reshaoc: any;
  type: any;
  titre;

  imageBaniere = "default.jpg";
  listActualite: any;

  url: string = environment.accet_url;

  loading_elemnt=true;

  constructor(
    private reshaocService: ReshaocService,
    private paramettreImageService: ParamettreImageService,
    private actualiteService: ActualiteService,
    private activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private location: Location
  ) {}

  onCloseHandled() {
    this.display = "none";
  }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

  reglementation: Reglementation;

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.type = params["type"];
      this.sowTitre();
      // this. getLastReshaoc()
      // this.reglementation.push(new Reglementation(1,"Comment acheter un serveur","bassarou","bassarou","bassarou"));
    });
  }

  sowTitre() {
    switch (this.type) {
      case "ACTIVITE-PHARE":
        this.titre = "LES ACTIVITES PHARES";
        this.getListActualite("ACTIVITE-PHARE");
        this.getImageBaniere("ACTIVITE-PHARE");
        break;
      case "ARTICLE":
        this.titre = "LES ARTICLES";
        this.getListActualite("ARTICLE");
        this.getImageBaniere("ARTICLE");
        break;
      case "AGENDA":
        this.titre = "LES AGENDAS";
        this.getListActualite("AGENDA");
        this.getImageBaniere("AGENDA");
        break;

      case "JOURNEE-SCIENTIFIQUE":
        this.titre = "LES JOURNEES SCIENTIFIQUE";
        this.getListActualite("JOURNEE-SCIENTIFIQUE");
        this.getImageBaniere("JOURNEE-SCIENTIFIQUE");

        break;

        case "AUTRE-EVERNEMENT":
        this.titre = "LES AUTRES  EVERNEMENTS";
        this.getListActualite("AUTRE-EVERNEMENT");
        this.getImageBaniere("AUTRE-EVERNEMENT");

        break;

      case "plan":
        this.titre = "PLAN STRATÉGIQUE RESHAOC";
        this.getListActualite("ACTIVITE-PHARE");
        this.getImageBaniere("ACTIVITE-PHARE");

        break;
      case "JOURNEE-HOSPITALIERE":
        this.titre = "LES JOURNÉES HOPITALIÈRE";
        this.getListActualite("JOURNEE-HOSPITALIERE");
        this.getImageBaniere("JOURNEE-HOSPITALIERE");

        break;

      case "JOURNEE-COOPERATION":
        this.titre = "LES JOURNÉES DE COOPÉRATION";
        this.getListActualite("JOURNEE-COOPERATION");
        this.getImageBaniere("JOURNEE-COOPERATION");

        break;
      case "PARTENARIAT-SUD-SUD":
        this.titre = "LES PARTENARIATS SUD-SUD";
        this.getListActualite("PARTENARIAT-SUD-SUD");
        this.getImageBaniere("PARTENARIAT-SUD-SUD");

        break;
      case "PARTENARIAT-INTER-HOSPITALIERE":
        this.titre = "LES PARTENARIATS INTER-HOSPITALIÈRES";
        this.getListActualite("PARTENARIAT-INTER-HOSPITALIERE");
        this.getImageBaniere("PARTENARIAT-INTER-HOSPITALIERE");

        break;
      case "COOPERATION-INTERNATIONALE":
        this.titre = "LES COOPÉRATIONS INTERNATIONALE";
        this.getListActualite("COOPERATION-INTERNATIONALE");
        this.getImageBaniere("COOPERATION-INTERNATIONALE");

        break;
      case "RELATION-APPUI-SPONSORING":
        this.titre = "LES RELATIONS D'APPUI ET SPONSORING";
        this.getListActualite("RELATION-APPUI-SPONSORING");
        this.getImageBaniere("RELATION-APPUI-SPONSORING");
        break;

      case "ACTIVITE-POINTS-FOCAUX":
            this.titre = "LES ACTIVITES DES POINTS FOCAUX";
            this.getListActualite("ACTIVITE-POINTS-FOCAUX");
            this.getImageBaniere("RELATION-APPUI-SPONSORING");

            break;

      case "FORMATION":
        this.titre = "LES FORMATIONS DU RESHAOC";
        this.getListActualite("FORMATION");
        this.getImageBaniere("RELATION-APPUI-SPONSORING");

        break;
    }
  }

  getListActualite(type): void {
    this.actualiteService.getByType(type).subscribe(
      (data: any) => {
        this.listActualite = data["data"];
        console.log(this.listActualite, type);
      },
      (error: HttpErrorResponse) => {
        console.log("Error while retrieving data");
      }
    );
  }

  getImageBaniere(type): void {

    this.loading_elemnt=true
    this.paramettreImageService.getImageByType(type).subscribe(
      (data: any) => {
        this.imageBaniere = data["data"].image;
        console.log(this.imageBaniere);

        this.loading_elemnt=false
      },
      (error: HttpErrorResponse) => {
        console.log("Error while retrieving data");
      }
    );
  }

  getLastReshaoc() {
    this.reshaocService.getReshaoc().subscribe(
      (data: any) => {
        console.log(data["data"]);

        // this.reshaoc=data['data']

        switch (this.type) {
          case "presentation":
            this.titre = "Présentation du RESHAOC";
            this.reshaoc = data["data"].presentation;
            break;

          case "mission":
            this.titre = "Mission du RESHAOC";
            this.reshaoc = data["data"].mission;
            break;
          case "objectif":
            this.titre = "Objectif du RESHAOC";
            this.reshaoc = data["data"].objectif;
            break;
          case "organisation":
            this.titre = "Organisation du RESHAOC";
            this.reshaoc = data["data"].organisation;
            break;

          case "plan":
            this.titre = "Plan stratégique RESHAOC";
            this.reshaoc = data["data"].plan;
            break;
        }

        // this.editorPresention.root.innerHTML=data['data'].presentation
        // this.editorMission.root.innerHTML=data['data'].mission
        // this.editorObjectif.root.innerHTML=data['data'].objectif
        // this.editorOrganisation.root.innerHTML=data['data'].organisation
        // this.editorPlan.root.innerHTML=data['data'].plan
      },
      (error: HttpErrorResponse) => {
        console.log("Error while retrieving data");
      }
    );
  }

  back(): void {
    this.location.back();
  }

  view_file(url) {
    console.log(url);
    this.display = "block";
  }
}
