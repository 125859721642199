export var  listEservice=[
    // {
    //   metier:'hebergement',
    //   afficher:'Hébergement touristique',
    //   libele:'Demande d’autorisation d’exploitation',
    //   lien:'https://pprod.service-public.bj/private/application/7a2195d9-76f1-4f32-8029-87a7a8d5ebe5/start'
    // },
    // {
    //     metier:'hebergement',
    //     afficher:'Hébergement touristique',
    //     libele:'Demande de classement',
    //     lien:'https://pprod.service-public.bj/private/application/01dccad5-602d-408a-9e27-9682a74a559d/start'
    // },
    // {
    //     metier:'hebergement',
    //     afficher:'Hébergement touristique',
    //     libele:'Demande de reclassement',
    //     lien:'https://pprod.service-public.bj/private/application/3d94154c-87d4-48b0-b6cf-4f2b6d790167/start'
    // },
    // {
    //     metier:'hebergement',
    //     afficher:'Hébergement touristique',
    //     libele:'Notifications diverses',
    //     lien:'https://pprod.service-public.bj/private/application/13a64f5f-9b0a-4719-9c31-2612fdb2d2e8/start'
    // },


     {
        metier:'hebergement',
        afficher:'Hébergement touristique',
        libele:'Demande d’autorisation d’exploitation et de classement initial d’un établissement d’hébergement touristique',
        lien:'https://pprod.service-public.bj/public/services/service/PS01279'
      },


      // {
      //     metier:'hebergement',
      //     afficher:'Hébergement touristique',
      //     libele:'Demande de classement',
      //     lien:'https://pprod.service-public.bj/public/services/service/PS01284'
      // },


      {
          metier:'hebergement',
          afficher:'Hébergement touristique',
          libele:'Demande de reclassement',
          lien:'https://pprod.service-public.bj/public/services/service/PS01285'
      },
      {
          metier:'hebergement',
          afficher:'Hébergement touristique',
          libele:'Notifications diverses',
          lien:'https://pprod.service-public.bj/public/services/service/PS01286'
      },
    {
        metier:'voyage',
        afficher:'Agence de voyage',
        libele:'Demande de licence',
        lien:'https://pprod.service-public.bj/public/services/service/PS01287'
    },
    {
        metier:'voyage',
        afficher:'Agence de voyage',
        libele:'Demande d’ouverture de succursale',
        lien:'https://pprod.service-public.bj/public/services/service/PS01288'
    },
    {
        metier:'voyage',
        afficher:'Agence de voyage',
        libele:'Demande de renouvellement de licence',
        lien:'https://pprod.service-public.bj/public/services/service/PS01289'
    },
    {
        metier:'voyage',
        afficher:'Agence de voyage',
        libele:'Notifications diverses',
        lien:'https://pprod.service-public.bj/public/services/service/PS01290'
    },
    // {
    //     metier:'voyage',
    //     afficher:'Agence de voyage',
    //     libele:'Cessation d’activité Notifications diverses (changements de directeur, changements statuts, fermetures ',
    //     lien:'https://pprod.service-public.bj/public/services/service/PS01289'
    // },
    {
        metier:'guide',
        afficher:'Guide de tourisme',
        libele:'Demande d’une carte professionnelle',
        lien:'https://pprod.service-public.bj/public/services/service/PS01292'
    },
    {
        metier:'guide',
        afficher:'Guide de tourisme',
        libele:'Demande de renouvellement de la carte professionnelle',
        lien:'https://pprod.service-public.bj/public/services/service/PS01293 '
    },
    {
        metier:'guide',
        afficher:'Guide de tourisme',
        libele:' Ajout d’une spécialité',
        lien:'https://pprod.service-public.bj/public/services/service/PS01294'
    },
    {
        metier:'guide',
        afficher:'Guide de tourisme',
        libele:'Changement d’affectation pour guide de tourisme',
        lien:'https://pprod.service-public.bj/public/services/service/PS01295'
    },
    {
        metier:'restaurant',
        afficher:'Établissement de restauration',
        libele:'Demande d’une autorisation d’exercer',
        lien:'https://pprod.service-public.bj/public/services/service/PS01296'
    },
    {
        metier:'restaurant',
        afficher:'Établissement de restauration',
        libele:'Demande de renouvellement d’une autorisation',
        lien:'https://pprod.service-public.bj/public/services/service/PS01297'
    },
    {
        metier:'restaurant',
        afficher:'Établissement de restauration',
        libele:'Notifications diverses',
        lien:'https://pprod.service-public.bj/public/services/service/PS01298'
    },
    {
        metier:'restaurant',
        libele:'Demande de classement',
        afficher:'Établissement de restauration',
        lien:'https://pprod.service-public.bj/public/services/service/PS01299'
    },
    {
        metier:'restaurant',
        afficher:'Établissement de restauration',
        libele:'Demande de renouvellement du classement',
        lien:'https://pprod.service-public.bj/public/services/service/PS01300'
    },
  ]