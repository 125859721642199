import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Actualite} from '../models/Actualite';


@Injectable({
  providedIn: 'root'
})
export class ActualiteService {

  url: string = environment.backend ;


  constructor(private http: HttpClient) { }


  getActualite(): Observable<Object> {
    return this.http.get(`${this.url}/getLastActualite`);
  }

  save(actualite: Actualite ): Observable<Object> {
    return this.http.post(`${this.url}/actualites`, actualite);
  }

  saveWithInmage(data:any,id: number):Observable<Object> {
    return this.http.post(`${this.url}/actualites`, data);
  }

  // Modification d'un element
  edit(data:any,id: number): Observable<Object> {
    return this.http.put(`${this.url}/${id}`, data);
  }




  // liste des IDE par id
  getById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/actualites/${id}`);
  }

  // liste des IDE par id
  getByType(type: any): Observable<Object> {
    return this.http.get(`${this.url}/getActuByType?type=${type}`);
  }


  getActuVedetteByType(type: any): Observable<Object> {
    return this.http.get(`${this.url}/getActuVedetteByType?type=${type}`);
  }
  getActuVedetteByType2(type: any): Observable<Object> {
    return this.http.get(`${this.url}/getActuVedetteByType2?type=${type}`);
  }




  getSponsore(key:any): Observable<Object> {

    return this.http.get(`${this.url}/getParamettreByKey/${key}`);

  }


  addReservation(data:any):Observable<Object> {
  return this.http.post(`${this.url}/addReservation`, data);
}



  listDocumentByType(type:any):Observable<Object> {
    return this.http.get(`${this.url}/listDocumentByType?type=${type}` );
  }





}