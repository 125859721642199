import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from 'app/components/profile';
import {MescotisationsComponent} from 'app/components/mescotisations';
import {AuthGuard} from 'app/utils/AuthGuard';
import {MesReclamationsComponent} from 'app/components/mesReclamations';
import {Mes_eservicesComponent} from 'app/components/MesEservices';
import { SettingsComponent } from 'app/components/settings';
import {DocproComponent} from '../components/doc-profesionnel';



const routes: Routes = [
  { path: 'user-profile',     component: ProfileComponent,canActivate :[AuthGuard] },
  { path: 'docpro', component:  DocproComponent },
  // { path: 'dashboard',      component: Mes_eservicesComponent, canActivate :[AuthGuard]},
  // { path: 'mesreclamations', component:  MesReclamationsComponent,canActivate :[AuthGuard]},

  { path: 'dashboard', component:  MescotisationsComponent },
  // { path: 'mesdemandes', component:  DocproComponent },


  { path: 'setting', component:  SettingsComponent,canActivate :[AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AccueilRoutingModule { }
