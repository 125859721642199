import {NgModule,Pipe, PipeTransform} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {PaysMembreComponent} from './pays-membre.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        PdfViewerModule

    ],
    declarations: [
        //DetailReglementationComponent
        PaysMembreComponent
    ],
    exports: [
        //DetailReglementationComponent
        PdfViewerModule,
    ]
})
export class PaysMembreModule {}
