import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {TokenStorage} from '../../utils/token.storage';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {ParamettreImageService} from '../../services/paramettre-image.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    user=[];
    profile_name='';
    image='';
    adminUrl: string = environment.backendOffice

    constructor(
        private paramettreImageService:ParamettreImageService,
        public location: Location, private element : ElementRef,private  tokenservice:TokenStorage,public router:Router) {
        this.sidebarVisible = false;
         
    }

    ngOnInit() {
        this.getAllParamettreSite();
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.user=JSON.parse(localStorage.getItem('agence'));

         // alert( JSON.parse(localStorage.getItem('paramettreSite'))['frais_adhesion_physique'])
      // alert(JSON.stringify(this.user ))

        if(this.tokenservice.getToken() !=null){
            this.image=this.user['image']
            this.profile_name = this.user['raison_sociale'];

            // if(localStorage.getItem('job')=='Guide'){
            //     this.profile_name=this.user['nom_promoteur'];
            //   }
            //   else{
            //       this.profile_name=localStorage.getItem('agence');
            //   }

        }

      
    }


    getAllParamettreSite(){
        this.paramettreImageService.getAllParamettreSite()
            .subscribe(response => {

                console.log(response['data']);

               // alert(JSON.stringify(response['data'].frais_adhesion_morale))
                localStorage.setItem('paramettreSite',JSON.stringify(response['data']))

                // this.actualiteForm.reset();
            }, (error: HttpErrorResponse) => {
                console.log("Error while saving data");
                // this.notificationForm(
                //     "danger",
                //     "Erreur lors de la recupreration des donnees!"
                // );
            })

    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        // console.log(toggleButton, 'toggle');

        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };

    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };

    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    isHome() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/home' ) {
            return true;
        }
        else {
            return false;
        }
    }

    isDocumentation() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    canShowProfile(){
        return  this.tokenservice.getToken() !=null ;
    } 

    logout(){
        this.tokenservice.signOut();
        localStorage.clear();
        this.router.navigate(['/home']);
    }
}