import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ComponentsModule } from 'app/components/components.module';
import { SidebarComponent } from 'app/shared/sidebar/sidebar.component';
import { AccueilRoutingModule } from './accueil-routing.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AccueilComponent} from './accueil.component';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        AccueilRoutingModule,
    ],
    declarations: [
        AccueilComponent,
        SidebarComponent
    ],
    exports: [
        AccueilComponent

    ]
})
export class AccueilModule  {

}
