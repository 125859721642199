import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Demand } from 'app/models/demand';
import { DemandService } from 'app/services/demand.service';
import { JobService } from 'app/services/job.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {AdherantService} from '../../services/adherant.service';
import {stringify} from '@angular/compiler/src/util';
import {Cotisation} from '../../models/cotisation';

@Component({
  selector: 'mescotisations',
  templateUrl: './mescotisations.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mescotisations.component.styl']
})
export class MescotisationsComponent {

  constructor(private adherantService: AdherantService, private modalService: NgbModal) {

  }

  frenchRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 sur ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} sur ${length}`;
  }

  demands: Array<Demand> = [];
  demand = [];
  selected_faq: any;
  type: string = 'Demand';

  typeNotificationForm: string;
  messageNotificationForm: string;
  isNotificationForm: boolean = false;

  display = "none";
  email: string;
  idAdherant: string;
  spinner = true;

  displayedColumns = ['id', 'montant', 'motif', 'date','status' ];
  dataSource: MatTableDataSource<Cotisation>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.email = JSON.parse(localStorage.getItem('agence')).email
    this.idAdherant = JSON.parse(localStorage.getItem('agence')).id

    // alert( this.idAdherant)
    // alert(JSON.stringify(JSON.parse(localStorage.getItem('agence'))))
    this.getCotisationByAdherantList();

  }

  openXl(content, id: number) {
    this.modalService.open(content, { size: 'xl' });
    // this.getDemandById(id)
  }

  // getDemandById(id: number): void {
  //   this.demandService.getById(id).subscribe((data: any) => {
  //     this.demand = data['data'];
  //   }, (error: HttpErrorResponse) => {
  //     console.log("Error while retrieving data");
  //   }
  //   )
  // }

  getCotisationByAdherantList(): void {
    this.adherantService.getCotisationByAdherant(this.idAdherant).subscribe((data: Array<Demand>) => {
      this.demands = data['data'];

      // alert(JSON.stringify(this.demands))
      console.log(this.demands)

      this.dataSource = new MatTableDataSource(data['data']);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.setPaginationLabelToFrench();
      this.spinner = false;
    }, (error: HttpErrorResponse) => {
      console.log("Error while retrieving data");
    }
    )
  }

  setPaginationLabelToFrench(){
    this.paginator._intl.itemsPerPageLabel = 'Elements par page:';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.previousPageLabel = 'Page précédente';
    this.paginator._intl.getRangeLabel = this.frenchRangeLabel;
  }

  formatDate(date: string) {
    const d = date.split("T");
    return d[0] + ' ' + d[1].substr(0, 8);
  }

  delete() { }

  notificationForm(type: string, msg: string) {
    this.typeNotificationForm = type;
    this.messageNotificationForm = msg;
    this.isNotificationForm = true;
  }

  closeNotificationForm() {
    this.isNotificationForm = false;
  }

  onCloseHandled() {
    this.display = "none";
  }



  //    ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  //  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

}
