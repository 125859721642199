import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Reclamation} from '../models/reclamation';
import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DemandService {

  url: string = environment.backend + '/eservice_demande';
  constructor(private http: HttpClient) { }

  token: string = environment.api_token;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`
  });


  // liste des Accord
  list(): Observable<Object> {
    return this.http.get(`${this.url}`,{headers: this.headers});
  }

  listByUser(omtId:string): Observable<Object> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("omt_id",omtId);
    return this.http.get(`${this.url}/getDemandeByOmtId`,{
       headers: this.headers ,
      params:queryParams
    });
  }
 
  // liste des IDE par id
  getById(id: number): Observable<Object> {
    return this.http.get(`${this.url}/getDemandeyId/${id}`,{headers: this.headers});
  }
}
