import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Mes_eservicesComponent} from './mes_eservices.component';
import { SidebarComponent } from 'app/shared/sidebar/sidebar.component';
import { SidebarModule } from 'app/shared/sidebar/sidebar.module';

@NgModule({
    imports: [
        CommonModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
      //  SidebarModule
    ],
    declarations: [
        Mes_eservicesComponent,
        // SidebarComponent
    ],
    exports: [
        Mes_eservicesComponent
    ]
})
export class Mes_eservicesModule {}
