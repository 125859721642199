import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';

import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {HttpErrorResponse} from '@angular/common/http';
import {ReglementationsService} from '../services/reglementations.service';
import {Reglementation} from '../models/Reglementation';
import {ReshaocService} from '../services/reshaoc.service';
import {ActualiteService} from '../services/actualite.service';
import {environment} from '../../environments/environment';


@Pipe({
    name: 'sanitizeHtml'
})


@Component({
    selector: 'detailReglementation',
    templateUrl: './show-actualites.component.html',
    styleUrls: ['./show-actualites.component.styl']
})
export class ShowActualitesComponent implements  OnInit,PipeTransform {

    display = "none";
    pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

    reshaoc:any
    type:any
    titre

    actualiteId:any


    listActualite:any;

    url: string = environment.accet_url
    constructor(private reshaocService: ReshaocService,private actualiteService:ActualiteService,private activatedRoute: ActivatedRoute,private _sanitizer:DomSanitizer,private location: Location) {

    }

    onCloseHandled() {
        this.display = "none";
    }


    transform(v:string):SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(v);
    }


    reglementation:Reglementation;



    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.actualiteId=params['id'];
            this.getActualiteById(this.actualiteId)

            // this.sowTitre();
           // this. getLastReshaoc()
            // this.reglementation.push(new Reglementation(1,"Comment acheter un serveur","bassarou","bassarou","bassarou"));
        })

    }


    // sowTitre(){
    //
    //     switch (this.type){
    //         case "ACTIVITE-PHARE":
    //             this.titre="Les activites phares"
    //             this.getListActualite("ACTIVITE-PHARE")
    //             break;
    //         case "ARTICLE":
    //             this.titre="Les articles"
    //             this.getListActualite("ARTICLE")
    //             break;
    //         case "AGENDA":
    //             this.titre="Les agendas"
    //             this.getListActualite("AGENDA")
    //             break;
    //
    //         case "JOURNEE-SCIENTIFIQUE":
    //             this.titre="Organisation du RESHAOC"
    //             this.getListActualite("JOURNEE-SCIENTIFIQUE")
    //
    //         break;
    //
    //         case "plan":
    //             this.titre="Plan stratégique RESHAOC"
    //             this.getListActualite("ACTIVITE-PHARE")
    //
    //             break;
    //
    //     }
    //
    // }

    getActualiteById(id): void {
        this.actualiteService.getById(id).subscribe((data: any) => {
                this.listActualite= data['data'];
                console.log( this.listActualite,id)

                //this.dataSource=data['data'];
                // this.dataSourceActivite = new MatTableDataSource(data['data']);

                // this.dataSourceActivite.paginator = this.paginator;
                // this.dataSourceActivite.sort = this.sort;
                // this.setPaginationLabelToFrench();

            }, (error: HttpErrorResponse) => {
                console.log("Error while retrieving data");
            }
        )
    }

    getLastReshaoc() {

        this.reshaocService.getReshaoc().subscribe((data: any) => {

            console.log(data['data'])

            // this.reshaoc=data['data']

            switch (this.type){
                case "presentation":
                    this.titre="Présentation du RESHAOC"
                    this.reshaoc=data['data'].presentation;
                    break;

                case "mission":
                    this.titre="Mission du RESHAOC"
                    this.reshaoc=data['data'].mission;
                    break;
                case "objectif":
                    this.titre="Objectif du RESHAOC"
                    this.reshaoc=data['data'].objectif;
                    break;
                case "organisation":
                    this.titre="Organisation du RESHAOC"
                    this.reshaoc=data['data'].organisation;
                    break;

                case "plan":
                    this.titre="Plan stratégique RESHAOC"
                    this.reshaoc=data['data'].plan;
                    break;

            }

            // this.editorPresention.root.innerHTML=data['data'].presentation
            // this.editorMission.root.innerHTML=data['data'].mission
            // this.editorObjectif.root.innerHTML=data['data'].objectif
            // this.editorOrganisation.root.innerHTML=data['data'].organisation
            // this.editorPlan.root.innerHTML=data['data'].plan

        }, (error: HttpErrorResponse) => {
            console.log("Error while retrieving data");
        });
    }

    back(): void {
      this.location.back()
    }
 
    view_file(url){
        
        console.log(url)
        this.display = "block";
    }
}
