import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParamettreImageService {

  url: string = environment.backend ;


  constructor(private http: HttpClient) { }

  // Enregistrement d'un element
  // {
  //   headers: {'Content-Type': 'multipart/form-data'}
  // }
  updateOrSaveImage(data: any ): Observable<Object> {
    return this.http.post(`${this.url}/updateOrSaveImage`, data);
  }

  getAllImage( ): Observable<Object> {
    return this.http.get(`${this.url}/getAllImage`);
  }
  getImageByType(type:any ): Observable<Object> {
    return this.http.get(`${this.url}/getImageByType?type=${type}`);
  }


  getAllParamettreSite(): Observable<Object> {
    return this.http.get(`${this.url}/getAllParamettreSite`);
  }




}