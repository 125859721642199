import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {TokenStorage} from './token.storage';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private tokenStorage: TokenStorage) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenStorage.getToken() !== null) {
      // logged in so return true
      return true;
    }

    // console.log("sdsadsa");

    // not logged in so redirect to login page with the return url

   this.router.navigate(['/operateurTouristique'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
