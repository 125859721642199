import {Component} from '@angular/core';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.styl']
})
export class SettingsComponent {
    
}
