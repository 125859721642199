import { Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import { Investisseur } from 'app/models/investisseur';

@Injectable({
  providedIn: 'root'
}) 
export class InvestisseurService {
  // url: string = environment.backend + '/faq';
  url: string = environment.backend + '/auth/send-email';
  mail_adresse=''
  name_adresse=''

  token: string = environment.api_token;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`
  });

  constructor(private http: HttpClient) { }
    // Enregistrement d'un element
    // save(i: Investisseur): Observable<Object> {
    //   // return this.http.post(`${this.url}/investisseur`, i);
    //   return this.http.get(`${this.url}/${this.name_adresse}/${this.mail_adresse}/${this.mail_adresse}/${i.objet}/${i.content}`);
    // }

    save(i: any): Observable<Object> {return this.http.get(`${this.url}/${this.name_adresse}/${this.mail_adresse}/${this.mail_adresse}/${i.objet}/${i.content}`,{headers: this.headers});}
  
}
