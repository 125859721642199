import {NgModule,Pipe, PipeTransform} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {PayerCotisationComponent} from './payer-cotisation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {NgWizardConfig, NgWizardModule, THEME} from 'ng-wizard';
import {ComponentsModule} from '../components/components.module';
const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default
};


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        PdfViewerModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        Ng2TelInputModule,
        NgWizardModule.forRoot(ngWizardConfig)


    ],
    declarations: [
        //DetailReglementationComponent
        PayerCotisationComponent
    ],
    exports: [
        //DetailReglementationComponent
        PdfViewerModule,
    ]
})
export class PayerCotisationModule {}
