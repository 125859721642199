import {Component, OnInit,ViewChild} from '@angular/core';
import { Job } from 'app/models/job';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Reclamation } from 'app/models/reclamation';
import { ReclamationsService } from 'app/services/reclamation.service';
import { JobService } from 'app/services/job.service';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'mesReclamations',
    templateUrl: './mesReclamations.component.html',
    styleUrls: ['./mesReclamations.component.styl']
})
export class MesReclamationsComponent {
    constructor(private reclamationService:ReclamationsService,private jobService: JobService) {
    
    }
 
    complaints: Array<Reclamation> = [];
    jobs: Array<Job> = [];
    selected_faq: any;
    type:string = 'reclamation';

    typeNotificationForm: string;
    messageNotificationForm: string;
    isNotificationForm: boolean = false;
  
    display = "none";
    email:string; 
    spinner =true ;
    displayedColumns: string[] = ['id', 'created_at','objet','complaint','statut'];
    dataSource = new MatTableDataSource<any>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    getUserList(): void {
      this.reclamationService.listByUser(this.email).subscribe((data: Array<Reclamation>) => {
        this.complaints = data['data'];
        this.dataSource.data = data['data'];
        this.spinner = false;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setPaginationLabelToFrench();
      }, (error: HttpErrorResponse) => {
        console.log("Error while retrieving data");
      }
      )
    }

    setPaginationLabelToFrench(){
      this.paginator._intl.itemsPerPageLabel = 'Elements par page:';
      this.paginator._intl.nextPageLabel = 'Page suivante';
      this.paginator._intl.previousPageLabel = 'Page précédente';
      this.paginator._intl.getRangeLabel = this.frenchRangeLabel;
    }
    frenchRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0 || pageSize === 0) {
        return `0 sur ${length}`;
      }
  
      length = Math.max(length, 0);
  
      const startIndex = page * pageSize;
  
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
  
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    }
  

    formatDate(date:string){
        const d = date.split("T");
        return d[0]+' '+d[1].substr(0, 8);
      }

    ngOnInit() {
        this.email = JSON.parse(localStorage.getItem('agence')).email
      this.getUserList();
    }


//     deleteElement(id:number){
//       this.reclamationService.delete(id).subscribe(response => {
//         this.notificationForm( "success", "Supression réussi !");
//         this.getList();
//        },(error: HttpErrorResponse)=>{
//         console.log("Error while deleting data");   
//       } )
//    }

    notificationForm(type: string, msg: string) {
      this.typeNotificationForm = type;
      this.messageNotificationForm = msg;
      this.isNotificationForm = true;
    }
  
    closeNotificationForm() {
      this.isNotificationForm = false;
    }
  
    onCloseHandled() {
      this.display = "none";
    }
  
    applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); 
      filterValue = filterValue.toLowerCase(); 
      this.dataSource.filter = filterValue;
    }
}