import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  url: string = environment.backend + '/role';
  token: string = environment.api_token;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`
  });


  constructor(private http: HttpClient) { }


    list(): Observable<Object> {
      return this.http.get(`${this.url}`,{headers: this.headers});
    }


  getByRole(role:string): Observable<Object> {
    return this.http.post(`${this.url}/listByRoles`,role,{headers: this.headers});
  }
  
}
