import { Component, OnInit } from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdherantServiceService} from '../../services/adherant-service.service';
import {RoleService} from '../../services/role.service';
import {TokenStorage} from '../../utils/token.storage';
import {Router} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

declare const $: any;
@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent implements OnInit {
    closeResult = '';
    inscriptionForm:FormGroup;
    connectForm:FormGroup;
    show_error: boolean = false; 
    error:string=''
    rols:any;
    typeNotificationForm: string;
    messageNotificationForm: string;
    isNotificationForm: boolean = false;
    triying_login:boolean = true;
    password_type="password";
    constructor(private modalService: NgbModal,
                private  tokenservice:TokenStorage,
                private roleService: RoleService,
                private  operateurService: AdherantServiceService,
                private router:Router) { }


    ngOnInit() {
        this.inscriptionForm = new FormGroup({
            ifu: new FormControl('', Validators.required),

        });

        this.connectForm = new FormGroup({
            adherantId: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });

        if (this.tokenservice.getToken() !=null){
           $('#islogin').removeClass('d-none');
            $('#nologin').addClass('d-none');

        }else {

           $('#nologin').removeClass('d-none');
            $('#islogin').addClass('d-none');

        }

        // this.roleService.getByRole("OPERATEUR_TOURISTIQUE").subscribe(
        //     (data:any)=>{
        //
        //         this.rols=data
        //         alert(JSON.stringify(this.rols));
        //     },
        //     (error)=>{
        //         alert("error");
        //     }
        //
        // );


    }

    get ifu(): any {
        return this.connectForm.get('ifu');
    }
    get password(): any {
        return this.connectForm.get('password');
    }

    showPassword(){
        if(this.password_type =="password"){
            this.password_type = "text";
        }
        else{
           this.password_type="password";
        }
    }

// {
//     "id": 15,
//     "raison_sociale": "OPEN ET FILS",
//     "forme_juridique": "daD",
//     "email": "yacouboubassarou@gmail.com",
//     "password": "v7DqK6Xp",
//     "status": "",
//     "telephone": "97602657",
//     "pays": "Bénin",
//     "ville": "Abomey-calavi",
//     "code_postale": "229",
//     "site_web": "wdwdwq.kj",
//     "adresse": "Abommey-calavi/zoca",
//     "categorie": "PERSONNE PYSIQUE",
//     "prenom_dirigeant": null,
//     "nom_dirigeant": null,
//     "email_dirigeant": null,
//     "created_at": "2023-11-06 15:25:47",
//     "updated_at": "2023-11-06 15:25:47"
// }

    onClickSubmitConnect(){

        if (this.connectForm.valid){
           this.triying_login = false;

            const formData =this.connectForm.value;
            this.operateurService.login(formData)
                .subscribe((response:object) => {
                    this.triying_login = false;

                    if(response['success']===true){
                        // alert(JSON.stringify(response));

                        console.log(response['data'])

                       this.connectForm.reset();

                        this.tokenservice.saveToken(response['data'].created_at);
                        // localStorage.setItem('job', response['data'].operateurTouristique.job.name);
                        // localStorage.setItem('adherant_id',  response['data'].id);
                        // localStorage.setItem('adherant_telephone',  response['data'].id);
                        // localStorage.setItem('adherant_raison_sociale',  response['data'].raison_sociale);
                        // localStorage.setItem('adherant_email', response['data'].email);

                        localStorage.setItem('agence', JSON.stringify(response['data']) );

                        // console.log(localStorage.getItem('agence').id)
                        this.modalService.dismissAll()

                        // setTimeout(()=>this.router.navigate(['/dashboard']),6000);

                       this.router.navigate(['/dashboard']) .then(() => {
                           window.location.reload();
                       });

                        // window.document.location.href='/#/dashboard'
                    }
                    else{
                        // alert(JSON.stringify(response));
                        this.triying_login = false;
                        this.show_error=true;
                        if(response['success']===false){
                            this.error="Ce compte n'existe pas !";
                        }

                        $('#spinner2').addClass('d-none');
                        console.log(this.triying_login)
                        this.notificationForm(
                            "danger",
                            'Identifiants incorrects !'
                        );
                    }
                 
                }, (error: HttpErrorResponse) => {
                    this.triying_login = false;
                    $('#spinner2').addClass('d-none');
                    console.log(this.triying_login)
                    this.notificationForm(
                        "danger",
                         'Identifiants incorrects !'
                    );
                 
                })
            this.triying_login = false;

        }

    }

    onClickSubmitInscription(){

        if (this.inscriptionForm.valid){
            //     $('#sbt_btn').removeClass('disabled');
            $('#spinner').removeClass('d-none');

            const formData =this.inscriptionForm.value;

          // alert(  formData.ifu);


            this.operateurService.getActeurByIfu({
                ifu:formData.ifu
            }).subscribe(
                (data)=>{

               // alert(JSON.stringify(data['status']));


               if ( data['status']=="found"){

                   const firtKey=Object.keys(data)[0];

                   const userData={
                       username:data[firtKey][0]['ifu'],
                       firstName:data[firtKey][0]['raison_sociale'],
                       lastName:data[firtKey][0]['nom_promoteur'],
                       tel:data[firtKey][0]['telephone'],
                       email:data[firtKey][0]['email'],
                       ifu:data[firtKey][0]['ifu'],
                       password:"1234",
                       role:[],
                       profession: "acteur touristique",
                       image:"url",
                   }

                 this.createActeur(userData)


               }else {


                    alert("Votre Entreprise n'existe pas dans la base de donnée \n Veiullez vous enregistrez via le service publique ")

                  window.open('https://pprod.service-public.bj/public/services/service/PS00000018 ', '_blank');

               }



                    // alert(JSON.stringify(  data[firtKey][0] ));
                },
                error => {
                    alert(error);

                }
            );

            // alert("OK");



            // this.modalService.dismissAll()


        }else {

            alert("Veuillez saisie votre numéro Ifu");

        }

    }

    createActeur(data:object){

        this.operateurService.addActeur(data).subscribe(
            (data:any)=>{

                if (data!=null){
                    this.open('\n \n\n\nNous venons par le présent mail vous notifier les identifiants de votre compte \n\n\n\n\n ');
                }else {

                    alert("Erreur system Veuillez recommencer")
                }

                // alert("ça marche : "+JSON.stringify(data));
            },
            (error)=>{
                alert(error);

            }
        )


    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    notificationForm(type: string, msg: string) {
        this.isNotificationForm = true;
        this.typeNotificationForm = type;
        this.messageNotificationForm = msg;
    }

    closeNotificationForm() {
        this.isNotificationForm = false;
    }

    isAlreadyLoggedIn(){
        return  this.tokenservice.getToken() !=null ;
    } 
}
