import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  url: string = environment.backend + '/jobs';

  constructor(private http: HttpClient) { }
  token: string = environment.api_token;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`
  });


    list(): Observable<Object> {
      return this.http.get(`${this.url}`, {headers: this.headers});
    }

}
