import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocproComponent} from './docpro.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        //SidebarModule
        BrowserAnimationsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule
    ],
    declarations: [
        DocproComponent,
        //SidebarComponent
    ],
    exports: [
        DocproComponent,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule
    ]
})
export class DocproModule {}
