import { Component, OnInit } from '@angular/core';
import { listEservice } from 'app/common/listEservice';

@Component({
  selector: 'app-operateur-touristique',
  templateUrl: './adherant.component.html',
  styleUrls: ['./adherant.component.css']
})
export class AdherantComponent implements OnInit {

  constructor() {

  }
  r=true;
  hebergementVisible=true;
  voyageVisible=true;
  guideVisible=true;
  restaurantVisible=true;
  available_service=[];
  display_text =true ;
 
  selectedChange(e){
    if(e.target.value == 'all'){
      this.available_service=this.listEservice;
      this.display_text =false ;
    }
    else if(e.target.value == ''){
        this.display_text =true ;
    }
    else{
      this.display_text =false ;
      let filtered = this.listEservice.filter(p => p['metier']==e.target.value)
      this.available_service=filtered;
    }
   
  }

  listEservice= listEservice;

  ngOnInit(): void {
   this.available_service=this.listEservice;
  }

}
