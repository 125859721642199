import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdherantComponent} from './adherant.component';
import {NavigationComponent} from '../navigation/navigation.component';
import {NavigationModule} from '../navigation/navigation.module';


@NgModule({
    imports: [
        CommonModule,

        NavigationModule
    ],
    declarations: [
        AdherantComponent
    ],
    exports: [
        AdherantComponent

    ]
})
export class AdherantModule {}
