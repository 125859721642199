
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';



const TOKEN_KEY = 'AuthToken';
const CURRENT_USER = 'CURRENT_USER';
const ROLE_ADMIN = 'ROLE_ADMIN';

const ROLE_USER = 'ROLE_USER';

@Injectable()
export class TokenStorage {


  expirationsession: number;
  constructor() {

   this.expirationsession = new Date().getTime() + environment.expirationTime;
  }

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(CURRENT_USER);
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY,  token);
  }



  public getToken(): string {

    // alert( sessionStorage.getItem(TOKEN_KEY));
    return sessionStorage.getItem(TOKEN_KEY);
  }


}
