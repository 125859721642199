import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ActualiteService} from '../services/actualite.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {environment} from '../../environments/environment';


@Pipe({
    name: 'sanitizeHtml'
})
@Component({
    selector: 'accuiel',
    templateUrl: './accuiel.component.html',
    styleUrls: ['./accueil.component.styl']
})
export class AccueilComponent implements OnInit,PipeTransform{
    public href: string = "";

    loading_elemnt=true

    constructor(private router: Router,private actualiteService:ActualiteService,
        private _sanitizer:DomSanitizer) {

    }


    activitePhareVedette :any

    sponsore:any

    articleVedette :any
    articleVedette2 :any

    agendaVedette :any
    url: string = environment.accet_url
    ngOnInit() {
        this.href = this.router.url;
        this.getActivitePhareVedette();
        this.getArticleVedette();
        this.getArticleVedette2();
        this.getAgendaVedette();
        this.getSponsore()
    }


    getSponsore(){
        this.actualiteService.getSponsore("SPONSORE").subscribe(response => {

            this.sponsore=response['data'].value
            // alert(JSON.stringify(response['data'].value))

        },error => {

        })


    }


    getActivitePhareVedette(){

        this.loading_elemnt=true
        this.actualiteService.getActuVedetteByType('ACTIVITE-PHARE').subscribe(
            response=>{
                this.activitePhareVedette=response['data'];
                // alert(JSON.stringify(response['data']))
                this.loading_elemnt=false

                console.log(response['data'])
            },error => {

            }
        );

    }



    getArticleVedette(){

        this.loading_elemnt=true
        this.actualiteService.getActuVedetteByType('ARTICLE').subscribe(
            response=>{
                this.articleVedette=response['data'];
                // alert(JSON.stringify(response['data']))
                this.loading_elemnt=false

                console.log(response['data'])
            },error => {

            }
        );

    }

    getArticleVedette2(){

        this.loading_elemnt=true
        this.actualiteService.getActuVedetteByType2('ARTICLE').subscribe(
            response=>{
                this.articleVedette2=response['data'];
                // alert(JSON.stringify(response['data']))
                this.loading_elemnt=false

                console.log(response['data'])
            },error => {

            }
        );

    }

    getAgendaVedette(){
        this.loading_elemnt=true
        this.actualiteService.getActuVedetteByType('AGENDA').subscribe(
            response=>{
                this.agendaVedette=response['data'];
                // alert(JSON.stringify(response['data']))
                this.loading_elemnt=false
                console.log(response['data'])
            },error => {

            }
        );

    }

    transform(v:string):SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(v);
    }

}
