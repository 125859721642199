import {Component} from '@angular/core';
import { RegisterService } from 'app/services/register.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import {countries} from 'app/common/country-data-store'
import {AdherantServiceService} from 'app/services/adherant-service.service'
import { OperateurTouristique } from 'app/models/operateur-touristique';
import { Observable } from 'rxjs';

declare const $: any;

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.styl']
})
export class ProfileComponent {
    user=[];
    job=''; 
   // profileForm: FormGroup;
    secureForm: FormGroup;

    selected_job: string;
    typeNotificationForm: string;
    messageNotificationForm: string;
    isNotificationForm: boolean = false;

    departements: any[] = [];
    communes: any[] = [];
    all_communes: any[] = [];
    user_info_1: any[] = [];
    commune_spinner: boolean = false;
    show_error: boolean = false; 
   
    selected_department: string;
    selected_commune: string;
    spinner:boolean = false;
    error_no_equal:boolean = false;
    
    public countries:any = countries;
    used_telephone = false;
    used_email = false;

    countrie_is_unknow_p = true ;
    countrie_is_unknow_d= true ;
    
    profileForm = new FormGroup({
        raison_sociale: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        site_web: new FormControl('', [Validators.required,Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
        telephone: new FormControl('', Validators.required),
        departement: new FormControl('', Validators.required),
        commune: new FormControl('', Validators.required),
        adresse: new FormControl('', Validators.required),
        nom_promoteur: new FormControl('', Validators.required),
        prenom_promoteur: new FormControl('', Validators.required),
        sexe_promoteur: new FormControl('', Validators.required),
        nationalite_promoteur: new FormControl('', Validators.required),
        nom_directeur: new FormControl('', Validators.required),
        prenom_directeur: new FormControl('', Validators.required),
        sexe_directeur: new FormControl('', Validators.required),
        nationalite_directeur: new FormControl('', Validators.required),
        date_de_naissance:new FormControl('', Validators.required),
        lieu_de_naissance:new FormControl('', Validators.required),
        image:new FormControl(''),
    });

    profil_img: File = null;

    d:string
    c:string

    spinner_get_departement = true ;
    spinner_get_commune = true ;
    formatted_phone = '' ;
    
    constructor(private http:HttpClient, private registerService: RegisterService,private operateurTouristiqueService:AdherantServiceService) {
       
    }

    onKeyDown(event: KeyboardEvent) {
        if(!parseInt(event.key)){
            event.preventDefault();
        }
    }

    getDepartment():void{
        this.registerService.get_department().subscribe((data: any) => {
            this.departements = data;
            // for (let index = 0; index < data.length; index++) {
            //     if(this.departements[index].libelle ===this.user['departement']){
            //         this.d=JSON.stringify(this.departements[index]) ;
            //         this.spinner_get_departement = false ;
            //         console.log(JSON.stringify(this.departements[index]))
            //         this.profileForm.patchValue({
            //             departement:this.departements[index],
            //         });
            //     }   
            // }
        }, (error: HttpErrorResponse) => {
            console.log("Error while retrieving data");
        }
        )
    }
   
    getAllCommunes():void{
        this.registerService.get_all_commune().subscribe((data: any) => {
            this.all_communes = data;
            this.onOptionsSelected(this.user['departement']);
            // for (let index = 0; index < data.length; index++) {
            //     if(data[index].libelle ===this.user['commune']){
            //         this.c= JSON.stringify(this.all_communes[index]) ;
            //         console.log(JSON.stringify(this.all_communes[index]))
            //         this.spinner_get_commune = false ;
            //         this.profileForm.patchValue({
            //             commune:JSON.stringify(this.all_communes[index]),
            //         });
            //     }   
            // }
        }, (error: HttpErrorResponse) => {
            console.log("Error while retrieving data");
        }
        )
    }

    onOptionsSelected(value:string){
        this.commune_spinner=true; 
        var  id = this.getTheCommuneAsObject(value).id
       // this.selected_department=JSON.parse(value).libelle;
        this.selected_department=value;
        this.registerService.get_commune(id).subscribe((data: any) => {
            this.communes = data;
            this.commune_spinner= false ;
        }, (error: HttpErrorResponse) => {
            console.log("Error while retrieving data");
        }
        )
   }

   onComSelected(){
   // this.selected_commune=JSON.parse(this.profileForm.get('commune').value).libelle;
    this.selected_commune=this.profileForm.get('commune').value;
   }


//    handleFileInput(files: FileList) {
//     this.fileToUpload = files.item(0);
//     console.log(this.fileToUpload)
//    }

    ngOnInit() {
        // $('.js-example-basic-single').select2();
        // $('#d_nat').select2();
        // $('#p_nat').select2();
        this.user=JSON.parse(localStorage.getItem('agence'));
        this.job = JSON.parse(localStorage.getItem('agence')).job.name
        this.selected_job =JSON.parse(localStorage.getItem('agence')).job.name;

       
        this.getDepartment();
        this.getAllCommunes();
       
       
            this.profileForm.patchValue({
                raison_sociale: this.user['raison_sociale'],
                job: this.user['job'],
                ifu: this.user['ifu'],
                email: this.user['email'],
                site_web: this.user['site_web'],
                telephone: this.user['telephone'],
                departement: this.user['departement'],
                commune: this.user['commune'],
                adresse: this.user['adresse'],
                nom_promoteur: this.user['nom_promoteur'],
                prenom_promoteur: this.user['prenom_promoteur'],
                sexe_promoteur: this.user['sexe_promoteur'],
                nationalite_promoteur: this.user['nationalite_promoteur'],
                nationalite_directeur: this.user['nationalite_directeur'],
                nom_directeur: this.user['nom_directeur'],
                prenom_directeur: this.user['prenom_directeur'],
                sexe_directeur: this.user['sexe_directeur'],
                date_de_naissance: this.user['date_de_naissance'],
                lieu_de_naissance: this.user['lieu_de_naissance'],
            });
        
           

        this.secureForm = new FormGroup({
                ancienMotdepasse: new FormControl('', Validators.required),
                nouveauMotdepasse: new FormControl('', [Validators.required,Validators.minLength(8)]),
                confirmationpasse: new FormControl('', Validators.required)
        });

    }
   
    telInputObject(obj) {
        obj.intlTelInput('setNumber', this.user['telephone'],);
    } 
    getNumber(e){
        this.formatted_phone =e ;
     }
    p_nat() {
        this.countrie_is_unknow_p = this.checkCountrieExist(this.profileForm.get('nationalite_promoteur').value);
        // this.profileForm.patchValue({
        //     nationalite_promoteur: $('#p_nat').select2('val'),
        // });
    }

    d_nat() {
        // this.profileForm.patchValue({
        //     nationalite_directeur: $('#d_nat').select2('val'),
        // });
        this.countrie_is_unknow_d = this.checkCountrieExist(this.profileForm.get('nationalite_directeur').value);

    }

    email_change() {
        if(this.user['email'] !== this.profileForm.get('email').value){
            this.ckeckExite('email', this.profileForm.get('email').value)
        }
        else{
            this.used_email = false;
        }
    }
    telephone_change() {
        if(this.user['telephone'] !== this.profileForm.get('telephone').value){
            this.ckeckExite('telephone', this.profileForm.get('telephone').value)
        }
        else{
            this.used_telephone = false;
        }
    }

    notificationForm(type: string, msg: string) {
        this.typeNotificationForm = type;
        this.messageNotificationForm = msg;
        this.isNotificationForm = true;
    }
    closeNotificationForm() {
        this.isNotificationForm = false;
    }

    selectedImage: File;
    imageSrc: any;
    onImageSelected(event: any) {
        if (event.target.files && event.target.files[0]) {  
          this.profil_img = event.target.files[0];    
          console.log(this.profil_img)    
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(event.target.files[0]);
        }
    }
    onClickSubmit(): void {
       // if(this.profileForm.valid){
            this.spinner = true ;
            $('#spinner').removeClass('d-none');
            const formData = this.profileForm.value;
            const id = JSON.parse(localStorage.getItem('agence')).id ;
          
            const data = new FormData();
            data.append('Content-Type', 'multipart/form-data');
            data.append('image', <File>this.profil_img);
            data.append('site_web', formData.site_web);
            data.append('email', formData.email);
            data.append('telephone', formData.telephone);
            data.append('status', 'nouveau');

              this.operateurTouristiqueService.updateProfile(data,id)
                .subscribe(response => {
                    if(response['status']==200){
                        this.notificationForm(
                            "success",
                            "Enregistrement réussi !"
                          );                
                        localStorage.setItem('agence',JSON.stringify(response['data']) );
                        localStorage.setItem('job',response['data'].job.name);
                        $('#spinner').addClass('d-none');
                        this.spinner = true ; 
                    }
                    else{
                        this.notificationForm(
                            "danger",
                            "Erreur de l\'enregistrement !"
                          );
                    }
                 
                }, (error: HttpErrorResponse) => {
                   
                    this.notificationForm(
                        "danger",
                        "Erreur de l\'enregistrement !"+ error
                      );
                 
                })
        // }
        // else{
        //     this.notificationForm(
        //         "danger",
        //         "Formulaire invalide"
        //       );
        // }
    }

    blur(){
        if(this.secureForm.get('confirmationpasse').value !== this.secureForm.get('nouveauMotdepasse').value){
            this.error_no_equal = true ;
            console.log('here')
            $('#btn_up_pass').addClass('disabled');
        }
        else{
            this.error_no_equal = false ;
            $('#btn_up_pass').removeClass('disabled'); 
        }
      
    }

    changePass(): void { 
        const formData = this.secureForm.value;
         const id = JSON.parse(localStorage.getItem('agence')).id ;
         if(this.secureForm.valid){
            $('#spinner1').removeClass('d-none');
            this.operateurTouristiqueService.changePass(formData,id)
            .subscribe((response:object) => {
                if(response['status']===200){
                      this.notificationForm(
                        "success",
                        "Mot de passe changé avec succès !"
                      );
                }
            }, (error: HttpErrorResponse) => {
                    this.notificationForm(
                        "danger",
                        "Erreur de la modification !"
                      );
                $('#spinner1').addClass('d-none');
            })
         }
         else{
            alert('Veuillez renseigner tous les champs !')
         }
       
    }

    get ancienMotDePasse(): any {
        return this.secureForm.get('ancienMotDePasse');
    }
    get nouveauMotDePasse(): any {
        return this.secureForm.get('nouveauMotDePasse');
    }
    get confirmationpasse(): any {
        return this.secureForm.get('confirmationpasse');
    }

    get rccm(): any {
        return this.profileForm.get('rccm');
    }
    get raison_sociale(): any {
        return this.profileForm.get('raison_sociale');
    }
    get job_ipt(): any {
        return this.profileForm.get('job');
    }
    get ifu(): any {
        return this.profileForm.get('ifu');
    }
    get email(): any {
        return this.profileForm.get('email');
    }
    get site_web(): any {
        return this.profileForm.get('site_web');
    }
    get telephone(): any {
        return this.profileForm.get('telephone');
    }
    get departement(): any {
        return this.profileForm.get('departement');
    }
    get commune(): any {
        return this.profileForm.get('commune');
    }
    get adresse(): any {
        return this.profileForm.get('adresse');
    }
    get code(): any {
        return this.profileForm.get('code');
    }
    get nom_promoteur(): any {
        return this.profileForm.get('nom_promoteur');
    }
    get prenom_promoteur(): any {
        return this.profileForm.get('prenom_promoteur');
    }
    get sexe_promoteur(): any {
        return this.profileForm.get('sexe_promoteur');
    }
    get nationalite_promoteur(): any {
        return this.profileForm.get('nationalite_promoteur');
    }
    get npi_promoteur(): any {
        return this.profileForm.get('npi_promoteur');
    }
    get nom_directeur(): any {
        return this.profileForm.get('nom_directeur');
    }
    get prenom_directeur(): any {
        return this.profileForm.get('prenom_promoteur');
    }
    get nationalite_directeur(): any {
        return this.profileForm.get('nationalite_directeur');
    }
    get sexe_directeur(): any {
        return this.profileForm.get('sexe_directeur');
    }
    get date_de_naissance(): any {
        return this.profileForm.get('date_de_naissance');
    }
    get lieu_de_naissance(): any {
        return this.profileForm.get('lieu_de_naissance');
    }
    get image(): any {
        return this.profileForm.get('image');
    }
    
    ckeckExite(champ, valeur) {

        let data = new FormData();
        data.append("champ", champ);
        data.append("valeur", valeur);

        (this.registerService.ckeckExite(data) as unknown as Observable<Object>).subscribe((d: boolean) => {
            if(d){
                switch (champ) {
                   
                    case 'telephone':
                        this.used_telephone= true ;
                        break;
                   
                    case 'email':
                        this.used_email= true ;
                        break;
                    default:
                    // code block
                }
            }
            else{
                switch (champ) {
                    
                    case 'telephone':
                        this.used_telephone= false ;
                        break;
                   
                    case 'email':
                        this.used_email= false ;
                        break;
                    default:
                    // code block
                } 
            }
           
        }, error => {

         });

    }

    checkCountrieExist(c:string){
        for (let index = 0; index < this.countries.length; index++) {
            if(this.countries[index]['name'] ===c){
                return true ;
            }   
        }
        return false ;
    }

    getTheDepartmentAsObject(d:string){
        console.log(this.departements)
        for (let index = 0; index < this.departements.length; index++) {
            console.log(this.departements[index].libelle,d);

            if(this.departements[index].libelle ===d){
                return JSON.stringify(this.departements[index]) ;
            }   
        }
    }

    getTheCommuneAsObject(c:string){
        for (let index = 0; index < this.all_communes.length; index++) {
            if(this.all_communes[index].departement.libelle ===c){
                //return JSON.stringify(this.communes[index]) ;
                //console.log(this.communes[index]);
                return this.all_communes[index].departement ;
            }   
        }
    }


    
}
