import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})


export class SidebarComponent implements OnInit {
  isAgenceDeVoyage=false;
  canShowAutoEval=false;
  
  constructor() { }

  @Input() selected_link = ''
  ngOnInit(): void {
   // if(localStorage.getItem('job')=='Hebergement' || localStorage.getItem('job')=='Restaurant' ){
    this.canShowAutoEval =true ;

  }

  myalert(){
    alert('Module en cours  developement ');
  }

}
