import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MesReclamationsComponent} from './mesReclamations.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    imports: [
        CommonModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
      //  SidebarModule
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatSelectModule

    ],
    declarations: [
        MesReclamationsComponent,
        // SidebarComponent
    ],
    exports: [
        MesReclamationsComponent
    ]
})
export class MesReclamationsModule {}
