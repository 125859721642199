import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs'; 
@Injectable({
  providedIn: 'root'
})
export class ContactService {
  url: string = environment.backend + '/auth/send-email';
  mail_adresse='anagoarmandine@gmail.com'
  name_adresse='dine'
  constructor(private http: HttpClient) { }
    save(i: any): Observable<Object> {
      return this.http.get(`${this.url}/${this.name_adresse}/${this.mail_adresse}/${i.objet}/${i.message}`);
    }
}
