import { HttpErrorResponse } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import { Faq } from '../../models/faq';
import { FaqsService } from '../../services/faq.service';

@Component({
    selector: 'Faq',
    templateUrl: './Faq.component.html',
    styleUrls: ['./Faq.component.styl']
})
export class FaqComponent implements OnInit{
    first_spinner=true;
    constructor(private faqService:FaqsService){
        
    }
   
     faq: Array<Faq>=[];  
     
   
        getList():void{

        this.faqService.list().subscribe((data:Array<Faq>)=>{

            
            this.faq=data['data'];
            this.first_spinner =false ;

            // alert(JSON.stringify(this.faq))
            // this.selected_faq = data['data'][0];

        },(error: HttpErrorResponse)=>{

            console.log("erreur au niveau de la liste");

        }
        )
    }

    // public data:any[] = [
    //    {
    //         question : 'qui peut adérer ?',  
    //         reponse :'tout le monde'
    //    },
    // ];
  
   
  
    ngOnInit(){
      
       this.getList();
       // this.faq= [
       //  {id: 1, question: "comment obtenir mes pieces ?", content: " En s'enregistrant et en fournissant les pieces demandé"},
       //  {id: 2, question: "comment quels sont les frais d'enregistrement ?", content: "les frais s'élèvent à 3900"},
       //  {id: 3, question: "Quel est le délais pour recevoir une licence", content: "le délai maximal est de 72 heures"}
       // ];

              console.log(this.faq);
       
    }
}
