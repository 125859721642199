import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgbDateStruct,NgbModal, ModalDismissReasons  } from '@ng-bootstrap/ng-bootstrap';
import {FaqsService} from '../services/faq.service';
import { RegisterService } from 'app/services/register.service';
import {Faq} from '../models/faq';
import {HttpErrorResponse,HttpClient, HttpResponse} from '@angular/common/http';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ContactService } from 'app/contact.service';
declare const $: any;

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})

export class ComponentsComponent implements OnInit {
    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    date: {year: number, month: number};
    model: NgbDateStruct;

    closeResult = '';

    hebergementJob:any;
    voyageJob:any;
    restarantJob:any;
    guideJob:any;

    typeNotificationForm: string;
    messageNotificationForm: string;
    isNotificationForm: boolean = false;
    fileToUpload: File = null;
    contactForm:FormGroup;
    spinner_save:boolean = true;
    formatted_phone =''
    phone_indicatif_error =false;
    submitted= false;
    statByJob=[];
   
    getJobs(){
        this.faqService.getByJobId(1).subscribe((data:Array<Faq>)=>{


                this.hebergementJob=data['data'];

            },(error: HttpErrorResponse)=>{

                console.log("erreur au niveau de la liste");

            }
        );
        this.faqService.getByJobId(3).subscribe((data:Array<Faq>)=>{


                this.voyageJob=data['data'];

            },(error: HttpErrorResponse)=>{
                console.log("erreur au niveau de la liste");

            }
        ) ;


        this.faqService.getByJobId(2).subscribe((data:Array<Faq>)=>{


                this.restarantJob=data['data'];

            },(error: HttpErrorResponse)=>{
                console.log("erreur au niveau de la liste");

            }
        )

        this.faqService.getByJobId(4).subscribe((data:Array<Faq>)=>{


                this.guideJob=data['data'];

            },(error: HttpErrorResponse)=>{
                console.log("erreur au niveau de la liste");

            }
        )


    }
    
    getStat(){
        this.registerService.getStatOpByJob().subscribe((data:Array<any>)=>{
                this.statByJob=data['data'];
            },(error: HttpErrorResponse)=>{
                console.log("erreur au niveau de la liste");
            }
        ); 
    }

    constructor(private faqService:FaqsService,
                private contactService:ContactService, 
                private renderer : Renderer2,
                private modalService: NgbModal,
                private registerService: RegisterService,

    ) {}


    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    ngOnInit() {

        this.getJobs();
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function (){
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function (){
                input_group[i].classList.remove('input-group-focus');
            });
        }

        this.contactForm = new FormGroup({
            objet: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.email,Validators.required]),
            message: new FormControl('', Validators.required)
        });
        this.getStat()
    }

    onContactSubmit(): any {
        this.submitted= true;
        if (!this.contactForm.invalid) {
        
            $('#sbt_btn').removeClass('disabled');
            this.spinner_save=false ;

            const formData = this.contactForm.value;
            let data = new FormData();

            // data.append('Content-Type', 'multipart/form-data');
          
            data.append('email', formData.email);
            data.append('objet', formData.objet);
            data.append('message', formData.message);

            this.contactService.save({
                objet:formData.objet,
                message:formData.message,
            } )
                .subscribe((response: HttpResponse<any>) => {
                    this.spinner_save=true ;
                    this.notificationForm(
                        "success",
                        "Mail envoyer avec succès !"
                    );
                    
                    $('html,body').animate({
                        scrollTop: $("#contact").offset().top
                    }, 'slow');

                    this.contactForm.reset();

                }, (error: HttpErrorResponse) => {
                    this.spinner_save=true ;
                    const errorString = JSON.stringify(error);
                    const statut =errorString.toString() ;
                    if(statut.includes("OK")){
                        this.notificationForm(
                            "success",
                            "Mail envoyer avec succès !"
                        );
                        this.contactForm.reset();
                    }
                    else{
                        this.notificationForm(
                            "danger",
                            "Erreur lors de l'enregistrement !"
                        );
                    }
                    this.submitted= false;
                    $('html,body').animate({
                        scrollTop: $("#contact").offset().top
                    }, 'slow'); 
                })

            $('#sbt_btn').removeClass('disabled');
          
        }
        else{
            $('html,body').animate({
                scrollTop: $("#contact").offset().top
              }, 'slow');
              return false;
        }
    }

    get email(): any {
        return this.contactForm.get('email');
    }
    get objet(): any {
        return this.contactForm.get('objet');
    }
    get message(): any {
        return this.contactForm.get('message');
    }
    
    notificationForm(type: string, msg: string) {
        this.typeNotificationForm = type;
        this.messageNotificationForm = msg;
        this.isNotificationForm = true;
    }

    closeNotificationForm() {
        this.isNotificationForm = false;
    }
}
