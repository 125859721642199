import {NgModule} from '@angular/core';
import { ComponentsModule } from 'app/components/components.module';
import {CommonModule} from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import {ProfileComponent} from './profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {Ng2TelInputModule} from 'ng2-tel-input';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        Ng2TelInputModule,
    ],
    declarations: [
        ProfileComponent
    ],
    exports: [
        ProfileComponent
    ]
})
export class ProfileModule {}
