/** @format */

import { Component, OnInit, Pipe, PipeTransform } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { HttpErrorResponse } from "@angular/common/http";
import { ReglementationsService } from "../services/reglementations.service";
import { Reglementation } from "../models/Reglementation";
import { ReshaocService } from "../services/reshaoc.service";
import { ActualiteService } from "../services/actualite.service";
import { environment } from "../../environments/environment";
import { ParamettreImageService } from "../services/paramettre-image.service";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdherantService} from '../services/adherant.service';

@Pipe({
  name: "sanitizeHtml",
})
@Component({
  selector: "detailReglementation",
  templateUrl: "./payer-cotisation.component.html",
  styleUrls: ["./payer-cotisation.component.styl"],
})
export class PayerCotisationComponent implements OnInit, PipeTransform {
  display = "none";
  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

  reshaoc: any;
  type: any;
  idAdherant: any;
  titre;
  formatted_phone = '';
  the_current_indicatif = '+229';

  imageBaniere = "default.jpg";
  listActualite: any;
  payMoMoForm : FormGroup;

  commune_spinner=false

  montantAdhesion="100"
  url: string = environment.accet_url;

  loading_elemnt=true;

  constructor(
    private reshaocService: ReshaocService,
    private paramettreImageService: ParamettreImageService,
    private actualiteService: ActualiteService,
    private activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private  adherantService:AdherantService,
    private router: Router,
    private location: Location
  ) {}

  onCloseHandled() {
    this.display = "none";
  }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

  reglementation: Reglementation;

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.idAdherant = params["id"];
      this.sowTitre();
      // this. getLastReshaoc()
      // this.reglementation.push(new Reglementation(1,"Comment acheter un serveur","bassarou","bassarou","bassarou"));
    });

    this.payMoMoForm = new FormGroup({
      operateurMomo: new FormControl('', [Validators.required]),
      telephoneMomo: new FormControl('', [Validators.required ]),
      nomMomo: new FormControl('', [Validators.required ]),
      prenomMomo: new FormControl('', [Validators.required ]),

      id_adherant: new FormControl('', [ ]),
      montantAdhesion: new FormControl('', [ ]),
      typePayement: new FormControl('', [ ]),
    });
  }

  sowTitre() {
    this.titre = "PAYER UNE COTISATION";
    // this.getListActualite("ACTIVITE-PHARE");
    this.getImageBaniere("ACTIVITE-PHARE");

  }


  payMomo(){

    // alert("PAYER COTISATION PAR MTN MOMO")


    // console.log(this.payMoMoForm.value);
    if (this.payMoMoForm.valid){


      this.payMoMoForm.get('montantAdhesion').setValue(this.montantAdhesion);
      this.payMoMoForm.get('id_adherant').setValue(this.idAdherant);
      this.payMoMoForm.get('typePayement').setValue("COTISATION ANNUELLE");


      // alert("le formulaire est valide");
      // alert(JSON.stringify(this.payMoMoForm.value));

      this.commune_spinner=true;

      this.adherantService.payMomo(this.payMoMoForm.value).subscribe(
          (response:any)=>{

            this.commune_spinner=false


            if (response['message']=='SUCCESSFUL'){
              alert("Votre payement a été effectué avec succès")
              this.router.navigate(['/dashboard'])

            }else {
              alert("Votre payement a echoué veuillez reprendre l'operation")
            }





          },
          (error:any)=>{

          }
      )



    }else {
      alert("le formulaire est invalide")

      // alert(JSON.stringify(this.payMoMoForm.value))
    }




  }

  getNumber(e) {
    this.formatted_phone = e;
  }


  getListActualite(type): void {
    this.actualiteService.getByType(type).subscribe(
      (data: any) => {
        this.listActualite = data["data"];
        console.log(this.listActualite, type);
      },
      (error: HttpErrorResponse) => {
        console.log("Error while retrieving data");
      }
    );
  }

  getImageBaniere(type): void {

    this.loading_elemnt=true
    this.paramettreImageService.getImageByType(type).subscribe(
      (data: any) => {
        this.imageBaniere = data["data"].image;
        console.log(this.imageBaniere);

        this.loading_elemnt=false
      },
      (error: HttpErrorResponse) => {
        console.log("Error while retrieving data");
      }
    );
  }

  getLastReshaoc() {
    this.reshaocService.getReshaoc().subscribe(
      (data: any) => {
        console.log(data["data"]);

        // this.reshaoc=data['data']

        switch (this.type) {
          case "presentation":
            this.titre = "Présentation du RESHAOC";
            this.reshaoc = data["data"].presentation;
            break;

          case "mission":
            this.titre = "Mission du RESHAOC";
            this.reshaoc = data["data"].mission;
            break;
          case "objectif":
            this.titre = "Objectif du RESHAOC";
            this.reshaoc = data["data"].objectif;
            break;
          case "organisation":
            this.titre = "Organisation du RESHAOC";
            this.reshaoc = data["data"].organisation;
            break;

          case "plan":
            this.titre = "Plan stratégique RESHAOC";
            this.reshaoc = data["data"].plan;
            break;
        }

        // this.editorPresention.root.innerHTML=data['data'].presentation
        // this.editorMission.root.innerHTML=data['data'].mission
        // this.editorObjectif.root.innerHTML=data['data'].objectif
        // this.editorOrganisation.root.innerHTML=data['data'].organisation
        // this.editorPlan.root.innerHTML=data['data'].plan
      },
      (error: HttpErrorResponse) => {
        console.log("Error while retrieving data");
      }
    );
  }

  back(): void {
    this.location.back();
  }

  view_file(url) {
    console.log(url);
    this.display = "block";
  }
}
