import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Demand } from 'app/models/demand';
import { DemandService } from 'app/services/demand.service';
import { JobService } from 'app/services/job.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {ActualiteService} from '../../services/actualite.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'mescotisations',
  templateUrl: './docpro.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./docpro.component.styl']
})
export class DocproComponent {

  constructor(private demandService: DemandService, private modalService: NgbModal,
              private actualiteService: ActualiteService,
              ) {

  }

  listDocument: any;
  url: string = environment.accet_url

  frenchRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 sur ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} sur ${length}`;
  }

  demands: Array<Demand> = [];
  demand = [];
  selected_faq: any;
  type: string = 'Demand';

  typeNotificationForm: string;
  messageNotificationForm: string;
  isNotificationForm: boolean = false;

  display = "none";
  email: string;
  idAdherant: string;
  spinner = true;
  newlist

  displayedColumns = ['id', 'numDemande', 'demandeDate', 'demande', 'nom_du_service', 'status', 'derniere_notification', 'action'];
  dataSource: MatTableDataSource<Demand>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.email = JSON.parse(localStorage.getItem('agence')).email
    this.idAdherant = JSON.parse(localStorage.getItem('agence')).omtId
    this.getDemandList();
    this.getListDocument("professionnel");

  }

  getListDocument(type){
    this.actualiteService.listDocumentByType(type).subscribe(
        (data: any) => {
          this.listDocument = data["data"];
          this.spinner=false
          console.log(this.listDocument, type);
        },
        (error: HttpErrorResponse) => {
          console.log("Error while retrieving data");
        }
    );

  }

  openXl(content, id: number) {
    this.modalService.open(content, { size: 'xl' });
    this.getDemandById(id)
  }

  getDemandById(id: number): void {
    this.demandService.getById(id).subscribe((data: any) => {
      this.demand = data['data'];
    }, (error: HttpErrorResponse) => {
      console.log("Error while retrieving data");
    }
    )
  }

  getDemandList(): void {
    this.demandService.listByUser(this.idAdherant).subscribe((data: Array<Demand>) => {
      this.demands = data['data'];
      this.dataSource = new MatTableDataSource(data['data']);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.setPaginationLabelToFrench();
      this.spinner = false;
    }, (error: HttpErrorResponse) => {
      console.log("Error while retrieving data");
    }
    )
  }

  setPaginationLabelToFrench(){
    this.paginator._intl.itemsPerPageLabel = 'Elements par page:';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.previousPageLabel = 'Page précédente';
    this.paginator._intl.getRangeLabel = this.frenchRangeLabel;
  }

  formatDate(date: string) {
    const d = date.split("T");
    return d[0] + ' ' + d[1].substr(0, 8);
  }

  delete() { }

  notificationForm(type: string, msg: string) {
    this.typeNotificationForm = type;
    this.messageNotificationForm = msg;
    this.isNotificationForm = true;
  }

  closeNotificationForm() {
    this.isNotificationForm = false;
  }

  onCloseHandled() {
    this.display = "none";
  }



  //    ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  //  }

  applyFilter(filterValue: string) {


    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    // this.dataSource.filter = filterValue;

      this.newlist=this.listDocument.filter(t=>t.titre ===filterValue);

      // alert(JSON.stringify(this.newlist));
    // this.listDocument=this.newlist


  }

}
