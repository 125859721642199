import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {TokenStorage} from './token.storage';
import {environment} from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private tokenStorage: TokenStorage) {


  }
  token: string = environment.api_token;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available


    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`
      }
    });

    // headers = new HttpHeaders({
    //   'Authorization': `Bearer ${this.token}`
    // });

    // if (this.tokenStorage.getToken()) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${this.tokenStorage.getToken()}`
    //     }
    //   });
    // }

    return next.handle(request);
  }
}
