import {Component} from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { InvestisseurService } from 'app/services/investisseur.service';
import { Investisseur } from 'app/models/investisseur';
import { HttpErrorResponse } from '@angular/common/http';

declare const $: any;

@Component({
    selector: 'EspaceInvestisseur',
    templateUrl: './EspaceInvestisseur.component.html',
    styleUrls: ['./EspaceInvestisseur.component.styl']
})

export class EspaceInvestisseurComponent {
    constructor(private inverstisseurService: InvestisseurService) { }
    investisseur: Investisseur;
    typeNotificationForm: string;
    messageNotificationForm: string;
    isNotificationForm: boolean = false;
    fileToUpload: File = null;
    inverstisseurForm:FormGroup;
    spinner_save:boolean = true;
    formatted_phone =''
    phone_indicatif_error =false;
    submitted= false;
    ngOnInit() {
    
       this. inverstisseurForm = new FormGroup({
            firstname: new FormControl('', Validators.required),
            lastname: new FormControl('', Validators.required),
            objet: new FormControl('', Validators.required),
            phone_number: new FormControl(undefined, [Validators.required]),
            email: new FormControl('', [Validators.email,Validators.required]),
            content: new FormControl('', Validators.required)
        });

    }
   
    getNumber(e){
        this.formatted_phone =e ;
    }
    
    onCountryChange(e){
       
        console.log(e.areaCodes[0])
    }

    
    onClickSubmit(): any {
        this.submitted= true;
        if (!this.inverstisseurForm.invalid) {
            if( this.formatted_phone==''){
                this.phone_indicatif_error=true;
                $('html,body').animate({
                    scrollTop: $("#top").offset().top
                  }, 'slow');
                  return false;
            }
            else{
                this.phone_indicatif_error=false;
            }
            $('#sbt_btn').removeClass('disabled');
            this.spinner_save=false ;

            const formData = this.inverstisseurForm.value;
            let data = new FormData();

            data.append('Content-Type', 'multipart/form-data');
            data.append('firstname', formData.firstname);
            data.append('lastname', formData.lastname);
            data.append('email', formData.email);
            data.append('phonenumber', formData.phone_number);
            data.append('objet', formData.objet);
            data.append('content', formData.content);

            this.inverstisseurService.save(
                {
                    objet:formData.objet,
                    message:formData.content
                }
                // new Investisseur(formData.firstname, formData.lastname,formData.email,formData.phonenumber,formData.objet,formData.objet)
                )
                .subscribe(response => {
                    this.spinner_save=true ;
                    this.notificationForm(
                        "success",
                        "Demande soumise avec succès!"
                    );
                    $('html,body').animate({
                        scrollTop: $("#top").offset().top
                    }, 'slow');

                    this.inverstisseurForm.reset();

                }, (error: HttpErrorResponse) => {
                    this.spinner_save=true ;
                    const errorString = JSON.stringify(error);
                    const statut =errorString.toString() ;
                    if(statut.includes("OK")){
                        this.notificationForm(
                            "success",
                            "Mail envoyer avec succès !"
                        );
                        this.inverstisseurForm.reset();
                    }
                    else{
                        this.notificationForm(
                            "danger",
                            "Erreur lors de l'enregistrement !"
                        );
                    }
                    $('html,body').animate({
                        scrollTop: $("#top").offset().top
                    }, 'slow');
                })

            $('#sbt_btn').removeClass('disabled');
            // $('#spinner').addClass('d-none')
          
        }
        else{
            console.log(this.inverstisseurForm.value)
            $('html,body').animate({
                scrollTop: $("#top").offset().top
              }, 'slow');
              return false;
        }
    }

    get firstname(): any {
        return this.inverstisseurForm.get('firstname');
    }
    get lastname(): any {
        return this.inverstisseurForm.get('lastname');
    }
    get email(): any {
        return this.inverstisseurForm.get('email');
    }
    get objet(): any {
        return this.inverstisseurForm.get('objet');
    }
      get content(): any {
        return this.inverstisseurForm.get('content');
      }
    get phone_number(): any {
        return this.inverstisseurForm.get('phone_number');
    }



    notificationForm(type: string, msg: string) {
        this.typeNotificationForm = type;
        this.messageNotificationForm = msg;
        this.isNotificationForm = true;
    }

    closeNotificationForm() {
        this.isNotificationForm = false;
    }
}
