import {Component,OnInit} from '@angular/core';
import { listEservice } from 'app/common/listEservice';
@Component({
    selector: 'dashboard2',
    templateUrl: './mes_eservices.component.html',
    styleUrls: ['./mes_eservices.component.styl']
})
export class Mes_eservicesComponent implements OnInit {

    auth_user_op_type = '';
    hebergementVisible=true;
    listEservice=listEservice;
    job= localStorage.getItem('job');
    available_service=[]; 
    
    ngOnInit() {
     switch (this.job) {
        case 'Hebergement':
            this.auth_user_op_type ='hebergement';
            break;
        case 'Voyage':
            this.auth_user_op_type ='voyage';
            break;
        case 'Guide':
            this.auth_user_op_type ='guide';
            break;
        case 'Restaurant':
            this.auth_user_op_type ='restaurant';
                break;               
        default:
            break;
     }
       for (let i = 0; i < this.listEservice.length; i++) {
            if(this.listEservice[i]['metier']==this.auth_user_op_type){
                this.available_service.push(this.listEservice[i]);
            } 
        }

    }

    
    
}
