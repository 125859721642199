import { HttpErrorResponse,HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Job } from '../../models/job';
import { JobService } from '../../services/job.service';
import { RegisterService } from 'app/services/register.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { Observable, of } from 'rxjs';
import { OperateurTouristique } from 'app/models/operateur-touristique';
import { Router } from '@angular/router';

import { countries } from 'app/common/country-data-store'
import {Countries} from '../../models/country';
import {AdherantService} from '../../services/adherant.service';
// import { Ng2TelInputModule } from 'ng2-tel-input';

// import * as intlTelInput from 'intl-tel-input';
// import 'intl-tel-input/build/js/utils';

declare const $: any;

@Component({
    selector: 'Register',
    templateUrl: './Adhere.component.html',
    styleUrls: ['./Adhere.component.styl']
})
export class AdhereComponent implements OnInit {
    jobs: Array<Job> = [];
    registerForm: FormGroup;
    payMoMoForm : FormGroup;
    payVisaForm : FormGroup;

    // departements: any[] = [
    //     'Alibori',
    //     'Atacora',
    //     'Atlantique',
    //     'Bogou',
    //     'Collines',
    //     'Couffo',
    //     'Donga',
    //     'Littoral',
    //     'Mono',
    //     'Ouémé',
    //     'Plateau',
    //     'Zou'
    // ];
    departements: any[] = [];
    communes: any[] = [];
    user_info_1: any[] = [];
    first_spinner: boolean = false;
    job_is_loading: boolean = true;
    commune_spinner: boolean = false;
    show_error: boolean = false;
    verifiying: boolean = false;
    show_error_incorect_code: boolean = false;

    selected_job: string = '';
    selected_department: string;
    selected_commune: string;
    selected_d_nat: string;
    selected_p_nat: string;
    typeNotificationForm: string;
    messageNotificationForm: string;
    isNotificationForm: boolean = false;
    isNotGuide: boolean = false;
    isHeb: boolean = false;
    isAg: boolean = false;
    used_telephone = false;
    used_email = false;
    used_ifu = false;
    // used_rccm = true;
    used_rccm = false;
    valid_rccm = false;

    failed_npi = false
    no_match_code = false
    valid_npi = false
    phone_indicatif_error = false;
    countrie_is_unknow_p = false;
    countrie_is_unknow_d = false;
    date_de_naissance_is_invalid = false;
    formatted_phone = '';
    saving: boolean = true;
    well_done: boolean = false;
    errors = [];
    the_current_indicatif = '+229';
    rccm_is_verifying_spinner = false;
     // public countries  = countries;

    idNewAderant
    montantAdhesion="10";

    countries :any


    categorie:any


    constructor(
        private http: HttpClient,
        private jobService: JobService,
        private registerService: RegisterService,
        private ngWizardService: NgWizardService,
        private router: Router,
        private  adherantService:AdherantService
    ) { }

    onKeyDown(event: KeyboardEvent) {
        if (!parseInt(event.key)) {
            event.preventDefault();
        }
    }


    /*
    codeChange(e){
           if(this.code.value !=''){
            this.registerService.compareCode(this.npi_promoteur.value, this.code.value).subscribe((data: any[]) => {
                if (data['status'] != 200) {
                    this.show_error_incorect_code = true;
                    this.no_match_code= false ;
                    return false
                }
                else {
                    if (data['data']['code'] != this.code.value) {
                        this.show_error_incorect_code = true;
                        this.no_match_code= false ;
                        return false
                    }
                    else{
                        this.no_match_code= true ;
                    }

                }

            }, (error: HttpErrorResponse) => {
                this.show_error_incorect_code = true;
                console.log("Error while retrieving data");
                return false
            })
          }   
    }

    */



    getJobList(): void {

        this.jobService.list().subscribe((data: Array<Job>) => {
            this.jobs = data['data'];
            this.job_is_loading = false;
        }, (error: HttpErrorResponse) => {
            console.log("Error while retrieving data");
        }
        )
    }

    getDepartment(): void {
        this.registerService.get_department().subscribe((data: Array<Job>) => {
            this.departements = data;
        }, (error: HttpErrorResponse) => {
            console.log("Error while retrieving data");
        }
        )
    }

    onChangeJob(value: string) {
        const val = JSON.parse(value).name;
        if (val == 'Guide') {
            this.isNotGuide = false;
        }
        else {
            this.isNotGuide = true;
        }
    }



    showVal(lang) {
        console.log(lang);
    }

    ngOnInit() {
        // JSON.parse(localStorage.getItem('agence'))


        this.registerForm = new FormGroup({

            raison_sociale: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            forme_juridique: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            email: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            telephone: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            pays: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            ville: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            code_postale: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            site_web: new FormControl('www.', [Validators.required, Validators.maxLength(255)]),
            adresse: new FormControl('', [Validators.required, Validators.maxLength(255)]),
            categorie: new FormControl( ''),

            prenom_dirigeant: new FormControl( '',[Validators.required, Validators.maxLength(255)]),
            nom_dirigeant: new FormControl( '',[Validators.required, Validators.maxLength(255)]),
            telephone_dirigeant: new FormControl( '',[Validators.required, Validators.maxLength(255)]),
            email_dirigeant: new FormControl( '',[Validators.required, Validators.maxLength(255)]),

            agree: new FormControl('', Validators.required),

        });

        this.payMoMoForm = new FormGroup({
            operateurMomo: new FormControl('', [Validators.required]),
            telephoneMomo: new FormControl('', [Validators.required ]),
            nomMomo: new FormControl('', [Validators.required ]),
            prenomMomo: new FormControl('', [Validators.required ]),

            id_adherant: new FormControl('', [ ]),
            montantAdhesion: new FormControl('', [ ]),
            typePayement: new FormControl('', [ ]),
        });



        $('#date_de_naissance').datepicker({
            format: "dd/mm/yyyy",
            endDate: "-3d",
            language: "fr",
            autoclose: true,
            todayHighlight: true
        });

        // const input = document.querySelector('#telephone');
        // console.log(input);
        // const iti = intlTelInput(input, {
        //   preferredCountries: ['fr'],
        //   separateDialCode: true,
        //   utilsScript: 'node_modules/intl-tel-input/build/js/utils.js',
        //   onlyCountries: ['fr'] // pour limiter la liste des pays
        // });

        this.countries = [
            { code: "AF", code3: "AFG", name: "Afghanistan", number: "004" },
            { code: "AL", code3: "ALB", name: "Albanie", number: "008" },
            { code: "DZ", code3: "DZA", name: "Algérie", number: "012" },
            { code: "AS", code3: "ASM", name: "Samoa américaines", number: "016" },
            { code: "AD", code3: "AND", name: "Andorre", number: "020" },
            { code: "AO", code3: "AGO", name: "Angola", number: "024" },
            { code: "AI", code3: "AIA", name: "Anguilla", number: "660" },
            { code: "AQ", code3: "ATA", name: "Antarctique", number: "010" },
            { code: "AG", code3: "ATG", name: "Antigua-et-Barbuda", number: "028" },
            { code: "AR", code3: "ARG", name: "Argentine", number: "032" },
            { code: "AM", code3: "ARM", name: "Arménie", number: "051" },
            { code: "AW", code3: "ABW", name: "Aruba", number: "533" },
            { code: "AU", code3: "AUS", name: "Australie", number: "036" },
            { code: "AT", code3: "AUT", name: "Autriche", number: "040" },
            { code: "AZ", code3: "AZE", name: "Azerbaïdjan", number: "031" },
            { code: "BS", code3: "BHS", name: "Bahamas (les)", number: "044" },
            { code: "BH", code3: "BHR", name: "Bahreïn", number: "048" },
            { code: "BD", code3: "BGD", name: "Bangladesh", number: "050" },
            { code: "BB", code3: "BRB", name: "Barbade", number: "052" },
            { code: "BY", code3: "BLR", name: "Bélarus", number: "112" },
            { code: "BE", code3: "BEL", name: "Belgique", number: "056" },
            { code: "BZ", code3: "BLZ", name: "Belize", number: "084" },
            { code: "BJ", code3: "BEN", name: "Bénin", number: "204" },
            { code: "BM", code3: "BMU", name: "Bermudes (les)", number: "060" },
            { code: "BT", code3: "BTN", name: "Bhoutan (le)", number: "064" },
            { code: "BO", code3: "BOL", name: "Bolivie (État plurinational de)", number: "068" },
            { code: "BQ", code3: "BES", name: "Bonaire, Saint-Eustache et Saba", number: "535" },
            { code: "BA", code3: "BIH", name: "Bosnie-Herzégovine (la)", number: "070" },
            { code: "BW", code3: "BWA", name: "Botswana (le)", number: "072" },
            { code: "BV", code3: "BVT", name: "Bouvet (l'Île)", number: "074" },
            { code: "BR", code3: "BRA", name: "Brésil (le)", number: "076" },
            { code: "IO", code3: "IOT", name: "Indien (le Territoire britannique de l'océan)", number: "086" },
            { code: "BN", code3: "BRN", name: "Brunéi Darussalam", number: "096" },
            { code: "BG", code3: "BGR", name: "Bulgarie (la)", number: "100" },
            { code: "BF", code3: "BFA", name: "Burkina Faso (le)", number: "854" },
            { code: "BI", code3: "BDI", name: "Burundi (le)", number: "108" },
            { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132" },
            { code: "KH", code3: "KHM", name: "Cambodge (le)", number: "116" },
            { code: "CM", code3: "CMR", name: "Cameroun (le)", number: "120" },
            { code: "CA", code3: "CAN", name: "Canada (le)", number: "124" },
            { code: "KY", code3: "CYM", name: "Îles Caïmans", number: "136" },
            { code: "CF", code3: "CAF", name: "République centrafricaine (la)", number: "140" },
            { code: "TD", code3: "TCD", name: "Tchad (le)", number: "148" },
            { code: "CL", code3: "CHL", name: "Chili (le)", number: "152" },
            { code: "CN", code3: "CHN", name: "Chine (la)", number: "156" },
            { code: "CX", code3: "CXR", name: "Île Christmas", number: "162" },
            { code: "CC", code3: "CCK", name: "Îles Cocos (les) ou Keeling (les)", number: "166" },
            { code: "CO", code3: "COL", name: "Colombie (la)", number: "170" },
            { code: "KM", code3: "COM", name: "Comores (les)", number: "174" },
            { code: "CD", code3: "COD", name: "Congo (la République démocratique du)", number: "180" },
            { code: "CG", code3: "COG", name: "Congo (le)", number: "178" },
            { code: "CK", code3: "COK", name: "Îles Cook (les)", number: "184" },
            { code: "CR", code3: "CRI", name: "Costa Rica (le)", number: "188" },
            { code: "HR", code3: "HRV", name: "Croatie (la)", number: "191" },
            { code: "CU", code3: "CUB", name: "Cuba", number: "192" },
            { code: "CW", code3: "CUW", name: "Curaçao", number: "531" },
            { code: "CY", code3: "CYP", name: "Chypre", number: "196" },
            { code: "CZ", code3: "CZE", name: "Tchéquie (la)", number: "203" },
            { code: "CI", code3: "CIV", name: "Côte d'Ivoire (la)", number: "384" },
            { code: "DK", code3: "DNK", name: "Danemark", number: "208" },
            { code: "DJ", code3: "DJI", name: "Djibouti", number: "262" },
            { code: "DM", code3: "DMA", name: "Dominique", number: "212" },
            { code: "DO", code3: "DOM", name: "République dominicaine (la)", number: "214" },
            { code: "EC", code3: "ECU", name: "Équateur (l')", number: "218" },
            { code: "EG", code3: "EGY", name: "Égypte (l')", number: "818" },
            { code: "SV", code3: "SLV", name: "El Salvador", number: "222" },
            { code: "GQ", code3: "GNQ", name: "Guinée équatoriale (la)", number: "226" },
            { code: "ER", code3: "ERI", name: "Érythrée (l')", number: "232" },
            { code: "EE", code3: "EST", name: "Estonie (l')", number: "233" },
            { code: "SZ", code3: "SWZ", name: "Eswatini (l')", number: "748" },
            { code: "ET", code3: "ETH", name: "Éthiopie (l')", number: "231" },
            { code: "FK", code3: "FLK", name: "Îles Falkland (les) [Malvinas]", number: "238" },
            { code: "FO", code3: "FRO", name: "Îles Féroé (les)", number: "234" },
            { code: "FJ", code3: "FJI", name: "Fidji (les)", number: "242" },
            { code: "FI", code3: "FIN", name: "Finlande (la)", number: "246" },
            { code: "FR", code3: "FRA", name: "France (la)", number: "250" },
            { code: "GF", code3: "GUF", name: "Guyane française (la)", number: "254" },
            { code: "PF", code3: "PYF", name: "Polynésie française (la)", number: "258" },
            { code: "TF", code3: "ATF", name: "Terres australes françaises (les)", number: "260" },
            { code: "GA", code3: "GAB", name: "Gabon (le)", number: "266" },
            { code: "GM", code3: "GMB", name: "Gambie (la)", number: "270" },
            { code: "GE", code3: "GEO", name: "Géorgie (la)", number: "268" },
            { code: "DE", code3: "DEU", name: "Allemagne (l')", number: "276" },
            { code: "GH", code3: "GHA", name: "Ghana (le)", number: "288" },
            { code: "GI", code3: "GIB", name: "Gibraltar", number: "292" },
            { code: "GR", code3: "GRC", name: "Grèce", number: "300" },
            { code: "GL", code3: "GRL", name: "Groenland", number: "304" },
            { code: "GD", code3: "GRD", name: "Grenade", number: "308" },
            { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312" },
            { code: "GU", code3: "GUM", name: "Guam", number: "316" },
            { code: "GT", code3: "GTM", name: "Guatemala", number: "320" },
            { code: "GG", code3: "GGY", name: "Guernesey", number: "831" },
            { code: "GN", code3: "GIN", name: "Guinée", number: "324" },
            { code: "GW", code3: "GNB", name: "Guinée-Bissau", number: "624" },
            { code: "GY", code3: "GUY", name: "Guyana", number: "328" },
            { code: "HT", code3: "HTI", name: "Haïti", number: "332" },
            { code: "HM", code3: "HMD", name: "Îles Heard-et-MacDonald", number: "334" },
            { code: "VA", code3: "VAT", name: "Saint-Siège (le)", number: "336" },
            { code: "HN", code3: "HND", name: "Honduras", number: "340" },
            { code: "HK", code3: "HKG", name: "Hong Kong", number: "344" },
            { code: "HU", code3: "HUN", name: "Hongrie", number: "348" },
            { code: "IS", code3: "ISL", name: "Islande", number: "352" },
            { code: "IN", code3: "IND", name: "Inde", number: "356" },
            { code: "ID", code3: "IDN", name: "Indonésie", number: "360" },
            { code: "IR", code3: "IRN", name: "Iran (République Islamique d')", number: "364" },
            { code: "IQ", code3: "IRQ", name: "Irak", number: "368" },
            { code: "IE", code3: "IRL", name: "Irlande", number: "372" },
            { code: "IM", code3: "IMN", name: "Île de Man", number: "833" },
            { code: "IL", code3: "ISR", name: "Israël", number: "376" },
            { code: "IT", code3: "ITA", name: "Italie", number: "380" },
            { code: "JM", code3: "JAM", name: "Jamaïque", number: "388" },
            { code: "JP", code3: "JPN", name: "Japon", number: "392" },
            { code: "JE", code3: "JEY", name: "Jersey", number: "832" },
            { code: "JO", code3: "JOR", name: "Jordanie", number: "400" },
            { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398" },
            { code: "KE", code3: "KEN", name: "Kenya", number: "404" },
            { code: "KI", code3: "KIR", name: "Kiribati", number: "296" },
            { code: "KP", code3: "PRK", name: "République populaire démocratique de Corée", number: "408" },
            { code: "KR", code3: "KOR", name: "République de Corée", number: "410" },
            { code: "KW", code3: "KWT", name: "Koweït", number: "414" },
            { code: "KG", code3: "KGZ", name: "Kirghizistan", number: "417" },
            { code: "LA", code3: "LAO", name: "République démocratique populaire lao", number: "418" },
            { code: "LV", code3: "LVA", name: "Lettonie", number: "428" },
            { code: "LB", code3: "LBN", name: "Liban", number: "422" },
            { code: "LS", code3: "LSO", name: "Lésotho", number: "426" },
            { code: "LR", code3: "LBR", name: "Libéria", number: "430" },
            { code: "LY", code3: "LBY", name: "Libye", number: "434" },
            { code: "LI", code3: "LIE", name: "Liechtenstein", number: "438" },
            { code: "LT", code3: "LTU", name: "Lituani", number: "440" },
            { code: "LU", code3: "LUX", name: "Luxembourg", number: "442" },
            { code: "MO", code3: "MAC", name: "Macao", number: "446" },
            { code: "MG", code3: "MDG", name: "Madagascar", number: "450" },
            { code: "MW", code3: "MWI", name: "Malawi", number: "454" },
            { code: "MY", code3: "MYS", name: "Malaisie", number: "458" },
            { code: "MV", code3: "MDV", name: "Maldives", number: "462" },
            { code: "ML", code3: "MLI", name: "Mali", number: "466" },
            { code: "MT", code3: "MLT", name: "Malte", number: "470" },
            { code: "MH", code3: "MHL", name: "Îles Marshall", number: "584" },
            { code: "MQ", code3: "MTQ", name: "Martinique", number: "474" },
            { code: "MR", code3: "MRT", name: "Mauritanie", number: "478" },
            { code: "MU", code3: "MUS", name: "Maurice", number: "480" },
            { code: "YT", code3: "MYT", name: "Mayotte", number: "175" },
            { code: "MX", code3: "MEX", name: "Mexique", number: "484" },
            { code: "FM", code3: "FSM", name: "Micronésie (États fédérés de)", number: "583" },
            { code: "MD", code3: "MDA", name: "Moldova (la République de)", number: "498" },
            { code: "MC", code3: "MCO", name: "Monaco", number: "492" },
            { code: "MN", code3: "MNG", name: "Mongolie", number: "496" },
            { code: "ME", code3: "MNE", name: "Monténégro", number: "499" },
            { code: "MS", code3: "MSR", name: "Montserrat", number: "500" },
            { code: "MA", code3: "MAR", name: "Maroc", number: "504" },
            { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508" },
            { code: "MM", code3: "MMR", name: "Myanmar (Birmanie)", number: "104" },
            { code: "NA", code3: "NAM", name: "Namibie", number: "516" },
            { code: "NR", code3: "NRU", name: "Nauru", number: "520" },
            { code: "NP", code3: "NPL", name: "Népal", number: "524" },
            { code: "NL", code3: "NLD", name: "Pays-Bas (les)", number: "528" },
            { code: "NC", code3: "NCL", name: "Nouvelle-Calédonie", number: "540" },
            { code: "NZ", code3: "NZL", name: "Nouvelle-Zélande", number: "554" },
            { code: "NI", code3: "NIC", name: "Nicaragua", number: "558" },
            { code: "NE", code3: "NER", name: "Niger (le)", number: "562" },
            { code: "NG", code3: "NGA", name: "Nigéria (le)", number: "566" },
            { code: "NU", code3: "NIU", name: "Niue", number: "570" },
            { code: "NF", code3: "NFK", name: "Île Norfolk", number: "574" },
            { code: "MP", code3: "MNP", name: "Mariannes du Nord (les)", number: "580" },
            { code: "NO", code3: "NOR", name: "Norvège (la)", number: "578" },
            { code: "OM", code3: "OMN", name: "Oman", number: "512" },
            { code: "PK", code3: "PAK", name: "Pakistan (le)", number: "586" },
            { code: "PW", code3: "PLW", name: "Palau", number: "585" },
            { code: "PS", code3: "PSE", name: "Palestine, État de", number: "275" },
            { code: "PA", code3: "PAN", name: "Panama", number: "591" },
            { code: "PG", code3: "PNG", name: "Papouasie-Nouvelle-Guinée", number: "598" },
            { code: "PY", code3: "PRY", name: "Paraguay", number: "600" },
            { code: "PE", code3: "PER", name: "Pérou", number: "604" },
            { code: "PH", code3: "PHL", name: "Philippines (les)", number: "608" },
            { code: "PN", code3: "PCN", name: "Pitcairn", number: "612" },
            { code: "PL", code3: "POL", name: "Pologne", number: "616" },
            { code: "PT", code3: "PRT", name: "Portugal", number: "620" },
            { code: "PR", code3: "PRI", name: "Porto Rico", number: "630" },
            { code: "QA", code3: "QAT", name: "Qatar", number: "634" },
            { code: "MK", code3: "MKD", name: "République de Macédoine du Nord", number: "807" },
            { code: "RO", code3: "ROU", name: "Roumanie", number: "642" },
            { code: "RU", code3: "RUS", name: "Fédération de Russie (la)", number: "643" },
            { code: "RW", code3: "RWA", name: "Rwanda", number: "646" },
            { code: "RE", code3: "REU", name: "Réunion (La)", number: "638" },
            { code: "BL", code3: "BLM", name: "Saint-Barthélemy", number: "652" },
            { code: "SH", code3: "SHN", name: "Sainte-Hélène, Ascension et Tristan da Cunha", number: "654" }, { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659" },
            { code: "LC", code3: "LCA", name: "Sainte-Lucie", number: "662" },
            { code: "MF", code3: "MAF", name: "Saint-Martin (partie française)", number: "663" },
            { code: "PM", code3: "SPM", name: "Saint-Pierre-et-Miquelon", number: "666" },
            { code: "VC", code3: "VCT", name: "Saint-Vincent-et-les Grenadines", number: "670" },
            { code: "WS", code3: "WSM", name: "Samoa", number: "882" },
            { code: "SM", code3: "SMR", name: "Saint-Marin", number: "674" },
            { code: "ST", code3: "STP", name: "Sao Tomé-et-Principe", number: "678" },
            { code: "SA", code3: "SAU", name: "Arabie saoudite", number: "682" },
            { code: "SN", code3: "SEN", name: "Sénégal", number: "686" },
            { code: "RS", code3: "SRB", name: "Serbie", number: "688" },
            { code: "SC", code3: "SYC", name: "Seychelles", number: "690" },
            { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694" },
            { code: "SG", code3: "SGP", name: "Singapour", number: "702" },
            { code: "SX", code3: "SXM", name: "Sint Maarten (partie néerlandaise)", number: "534" },
            { code: "SK", code3: "SVK", name: "Slovaquie", number: "703" },
            { code: "SI", code3: "SVN", name: "Slovénie", number: "705" },
            { code: "SB", code3: "SLB", name: "Salomon (Îles)", number: "090" },
            { code: "SO", code3: "SOM", name: "Somalie", number: "706" },
            { code: "ZA", code3: "ZAF", name: "Afrique du Sud", number: "710" },
            { code: "GS", code3: "SGS", name: "Géorgie du Sud-et-les Îles Sandwich du Sud", number: "239" },
            { code: "SS", code3: "SSD", name: "Soudan du Sud", number: "728" },
            { code: "ES", code3: "ESP", name: "Espagne", number: "724" },
            { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144" },
            { code: "SD", code3: "SDN", name: "Soudan (le)", number: "729" },
            { code: "SR", code3: "SUR", name: "Suriname", number: "740" },
            { code: "SJ", code3: "SJM", name: "Svalbard et Jan Mayen", number: "744" },
            { code: "SE", code3: "SWE", name: "Suède", number: "752" },
            { code: "CH", code3: "CHE", name: "Suisse", number: "756" },
            { code: "SY", code3: "SYR", name: "République arabe syrienne", number: "760" },
            { code: "TW", code3: "TWN", name: "Taïwan", number: "158" },
            { code: "TJ", code3: "TJK", name: "Tadjikistan", number: "762" },
            { code: "TZ", code3: "TZA", name: "Tanzanie, République-Unie", number: "834" },
            { code: "TH", code3: "THA", name: "Thaïlande", number: "764" },
            { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626" },
            { code: "TG", code3: "TGO", name: "Togo", number: "768" },
            { code: "TK", code3: "TKL", name: "Tokelau", number: "772" },
            { code: "TO", code3: "TON", name: "Tonga", number: "776" },
            { code: "TT", code3: "TTO", name: "Trinité-et-Tobago", number: "780" },
            { code: "TN", code3: "TUN", name: "Tunisie", number: "788" },
            { code: "TR", code3: "TUR", name: "Turquie", number: "792" },
            { code: "TM", code3: "TKM", name: "Turkménistan", number: "795" },
            { code: "TC", code3: "TCA", name: "Îles Turks et Caicos (les)", number: "796" },
            { code: "TV", code3: "TUV", name: "Tuvalu", number: "798" },
            { code: "UG", code3: "UGA", name: "Ouganda", number: "800" },
            { code: "UA", code3: "UKR", name: "Ukraine", number: "804" },
            { code: "AE", code3: "ARE", name: "Émirats arabes unis (les)", number: "784" },
            { code: "GB", code3: "GBR", name: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)", number: "826" },
            { code: "UM", code3: "UMI", name: "Îles mineures éloignées des États-Unis (les)", number: "581" },
            { code: "US", code3: "USA", name: "États-Unis d'Amérique (les)", number: "840" },
            { code: "UY", code3: "URY", name: "Uruguay", number: "858" },
            { code: "UZ", code3: "UZB", name: "Ouzbékistan", number: "860" },
            { code: "VU", code3: "VUT", name: "Vanuatu", number: "548" },
            { code: "VE", code3: "VEN", name: "Venezuela (République bolivarienne du)", number: "862" },
            { code: "VN", code3: "VNM", name: "Vietnam", number: "704" },
            { code: "VG", code3: "VGB", name: "Îles Vierges (britanniques)", number: "092" },
            { code: "VI", code3: "VIR", name: "Îles Vierges (États-Unis)", number: "850" },
            { code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876" },
            { code: "EH", code3: "ESH", name: "Wallis et Futuna", number: "732" },
            { code: "YE", code3: "YEM", name: "Yémen", number: "887" },
            { code: "ZM", code3: "ZMB", name: "Zambie", number: "894" },
            { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716" },
            { code: "AX", code3: "ALA", name: "Iles Aland", number: "248" }
        ];

        console.log(this.countries )

    }



    payMomo(){

        // console.log(this.payMoMoForm.value);
        if (this.payMoMoForm.valid){


            this.payMoMoForm.get('montantAdhesion').setValue(this.montantAdhesion);
            this.payMoMoForm.get('id_adherant').setValue(this.idNewAderant);
            this.payMoMoForm.get('typePayement').setValue("FRAIS ADHESION");


             // alert("le formulaire est valide");
            // alert(JSON.stringify(this.payMoMoForm.value));

            this.commune_spinner=true;

            this.adherantService.payMomo(this.payMoMoForm.value).subscribe(
                (response:any)=>{

                    this.commune_spinner=false


                   if (response['message']=='SUCCESSFUL'){
                       alert("Votre payement a été effectué avec succès")
                       this.router.navigate(['/home'])

                   }else {
                       alert("Votre payement a echoué veuillez reprendre l'operation")
                   }





                },
                (error:any)=>{

                }
            )



        }else {
         alert("le formulaire est invalide")

            // alert(JSON.stringify(this.payMoMoForm.value))
        }




    }

    sendRequest() {

        // initiale body for request  ==> m = mandatory = obligatoire
        var data = {
            "type": "all", // in checkout value is mobile/card/all ==> m
            "transref": new Date().getTime(), // transref ===> m et unique
            "qosKey": "QCBJ001", // is provided by qos platform  ==> m
            "returnUrl": "https://www.qosic.com", // is callback redirection with parameter transref ans status ==> m
            "amountDetails": {
                "totalAmount": 100, // amount wil be pay by customer //  ==> m
                "currency": "XOF"
            },
            "saleDetails": {
                "firstName": "jac", // m
                "lastName": "Djac", // m
                "middleName": "KJ",
                "nameSuffix": "jac",
                "title": "Mr",  // m
                "address1": "Cotonou", // m
                "address2": "Cococodji",
                "address4": "string",
                "locality": "Litoral",   // m
                "administrativeArea": "",
                "postalCode": "229",  // m
                "country": "Benin",  // m
                "district": "Bj",   // m
                "buildingNumber": "string",
                "email": "jacques@qosic.com",  // m
                "emailDomain": "string",
                "phoneNumber": "66895585",   // m
                "phoneType": "cel"  // m
            }
        }

        fetch("https://b-card.qosic.net/public/v1/initTransaction", {
        // fetch("https://b-card.qosic.net/public/v1/initTransaction", {
            // fetch("http://localhost:9011/public/v1/initTransaction", {
       // fetch("http://74.208.84.251:9014/public/v1/initTransaction", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(res => {console.log(res)

                window.location.href = res.url

                // console.log("Request complete! response:", res);
            });

        /*  var xhr = new XMLHttpRequest();
         xhr.open("POST", 'https://b-checkout-api.qosic.net/public/v1/initTransaction', true);
         xhr.setRequestHeader('Content-Type', 'application/json');
         xhr.onreadystatechange = function () {
             if (this.readyState != 4) return;

             if (this.status == 200) {
                 var data = JSON.parse(this.responseText);
                 console.log(data)

                 // we get the returned data
             }

             // end of state change: it can be after some time (async)
         };
         xhr.send(JSON.stringify({
             value: data
         })); */

    }



    get raison_sociale(): any {

        return this.registerForm.get('raison_sociale');
    }
  get email(): any {

        return this.registerForm.get('raison_sociale');
    }

    get forme_juridique(): any {

        return this.registerForm.get('email');
    }

    get telephone(): any {

        return this.registerForm.get('telephone');
    }
    categorie_change(event){
        this.categorie=this.registerForm.get('categorie').value

        // alert(this.categorie)

        if (this.categorie=="PERSONNE MORALE"){

            this.montantAdhesion=JSON.parse(localStorage.getItem('paramettreSite'))['frais_adhesion_morale'];
        }else {
            this.montantAdhesion=JSON.parse(localStorage.getItem('paramettreSite'))['frais_adhesion_physique'];

        }

        // alert( this.montantAdhesion)

    }

    get agree(): any {
        return this.registerForm.get('agree');
    }

    isValidFunctionReturnsBoolean(args: StepValidationArgs) {
        if (args.direction == 'forward') {
            switch (args.fromStep.index) {
                case 0:
                    var condition = false;

                    condition = this.raison_sociale.value == '' || this.raison_sociale.invalid
                        || this.forme_juridique.invalid
                        || this.email.invalid

                    if(condition){
                        this.errors['forme_juridique'] = this.raison_sociale.invalid;
                        this.errors['raison_sociale'] = this.raison_sociale.invalid;
                        return false
                    }

                    break;
                case 1:


                    break;
                case 2:

                    var condition = false;
                    condition = this.agree.value == '' || this.agree.invalid;

                    if(condition){
                        this.errors['agree'] = this.agree.invalid

                        return false

                    }


                    // var condition = false;
                    //
                    // condition = this.agree.invalid
                    // if(condition){
                    //     this.errors['agree'] = this.raison_sociale.invalid;
                    //
                    //     return false
                    // }



                    // if ( this.agree.registerForm.get('agree'))


                    break;
                case 3:

                    // alert('')


                    break;
                default:
                    break;
            }
        }
        this.show_error = false;
        return true;
    }



    // email_change() {
    //     this.ckeckExite('email', this.registerForm.get('email').value)
    //     this.errors['email'] = this.email.invalid
    // }

    p_pat() {
        this.registerForm.patchValue({
            pays: $('#p_pays').select2('val'),
        });
        this.countrie_is_unknow_p = this.checkCountrieExist(this.registerForm.get('pays').value);
        // this.errors['nationalite_promoteur'] = this.nationalite_promoteur.invalid;
    }

    d_nat() {
        // this.registerForm.patchValue({
        //     nationalite_directeur: $('#d_nat').select2('val'),
        // });
        this.countrie_is_unknow_d = this.checkCountrieExist(this.registerForm.get('nationalite_directeur').value);
    }

    stepStates = {
        normal: STEP_STATE.normal,
        disabled: STEP_STATE.disabled,
        error: STEP_STATE.error,
        hidden: STEP_STATE.hidden
    };

    canShowPreviousBtn: false;
    config: NgWizardConfig = {
        selected: 0,
        theme: THEME.dots,
        lang: {
            next: 'Suivant',
            previous: 'Précédent'

        },
        toolbarSettings: {
            showPreviousButton: false,
            showNextButton: false,
            //   toolbarExtraButtons: [
            //     { 
            //         text: 'Enregistrer', 
            //          class: 'btn btn-primary', 
            //         event: () => {
            //            this.onClickSubmit();
            //          } 
            //     }
            //   ],
        }
    };

    notificationForm(type: string, msg: string) {
        this.isNotificationForm = true;
        this.typeNotificationForm = type;
        this.messageNotificationForm = msg;
    }

    naviagateTo() {
        this.router.navigate(['#'])
    }

    closeNotificationForm() {
        this.isNotificationForm = false;
        var r = this.router;
        setTimeout(function () {
            r.navigate(['#'])
        }, 2000, r);
    }


    // onCountryChange(e) {
    //     this.the_current_indicatif = '+' + e.dialCode
    //     this.ckeckExite('telephone', this.the_current_indicatif + this.registerForm.get('telephone').value)
    //     this.errors['telephone'] = this.telephone.invalid
    // }

    getNumber(e) {
        this.formatted_phone = e;
    }




    /*

    telephone_change(e) {
        const title = document.getElementsByClassName("iti__selected-flag")[0].getAttribute("title");
        const indicatif = title.split(":")[1];
        this.the_current_indicatif = indicatif.replace(/\s/g, '');

        // this.ckeckExite('telephone', this.registerForm.get('telephone').value)
        this.ckeckExite('telephone', this.the_current_indicatif + this.registerForm.get('telephone').value)
        this.errors['telephone'] = this.telephone.invalid

    }

    ifu_change() {
        this.ckeckExite('ifu', this.registerForm.get('ifu').value)
        this.errors['ifu'] = this.ifu.invalid
    }
    rccm_change() {
        this.ckeckExite('rccm', this.registerForm.get('rccm').value)
        this.errors['rccm'] = this.rccm.invalid
    }

    */

    onClickSubmit(): void {
        this.saving = false;
        const formData = this.registerForm.value;


        var job_id = JSON.parse(formData.job).id;



    }

    showPreviousStep(event?: Event) {
        this.ngWizardService.previous();
    }

    showNextStep(event?: Event) {
        this.ngWizardService.next();
    }

    resetWizard(event?: Event) {
        this.ngWizardService.reset();
    }

    setTheme(theme: THEME) {
        this.ngWizardService.theme(theme);
    }

    stepChanged(args: StepChangedArgs) {

        if (args.step.index != 0) {
            this.config.toolbarSettings.showPreviousButton = true;
            // args.step.index = 2;
            // this.showNextStep();
        }
        else {
            this.config.toolbarSettings.showPreviousButton = false;
        }




       if (args.step.index == 1   && args.direction == 'forward'   ) {
         // args.step.index = 2;
         //   this.showNextStep();

           if (this.categorie !="PERSONNE MORALE"){
                args.step.index = 2;
                  this.showNextStep();
           }
           // alert(this.categorie)
           // alert(JSON.stringify(this.registerForm.value))
       }


        if (args.step.index ==3   && args.direction == 'forward'   ) {
            // args.step.index = 2;
            //   this.showNextStep();

            this.commune_spinner=true
            // this.rccm_is_verifying_spinner=false
            //
            // this.rccm_is_verifying_spinner=false


            this.saving = false;
            const formData = this.registerForm.value;

            // this.sendRequest()


            this.adherantService.save(formData).subscribe((response:any)=>{

                // alert("bien enregistrer")

                if (response['success']==true){
                    this.isNotificationForm=true;
                    this.notificationForm('success',"La soumission de votre adhésion est bien enregistrée. \n  Votre identifiant et mot de passe d’accès a l’espace web professionnel du  RESAHOC\n" +
                        "vous ont été envoyé par mail\n" +
                        "Pour activer votre compte veuillez procéder au paiement en suivant les étapes ci-dessous")
                    this.commune_spinner=false;

                    // alert(JSON.stringify(response['data'].id))

                    this.idNewAderant=response['data'].id
                }else
                {

                    console.log(response['message']);
                    alert(JSON.stringify(response['message']));

                    this.isNotificationForm=true;
                    // this.notificationForm('danger',"l'email, raison sociale ou le numéro de téléphone existé déjà dans la base veuillez vérifier")
                    this.notificationForm('danger',response['message']+" veuillez vérifier")
                    // alert("l'email existe deja")
                    this.commune_spinner=false;
                    // args.step.index = 0;
                    this.showPreviousStep()
                    this.showPreviousStep()
                    this.showPreviousStep()

                }



            },(error:HttpErrorResponse)=>
            {

                // console.log(error);
                // alert(JSON.stringify(error));

                this.isNotificationForm=true;
                this.notificationForm('danger',"Erreur System veuillez verifier votre connection internet ou contater l'administreateur")
                // alert("l'email existe deja")
                this.commune_spinner=false;
                // args.step.index = 0;
                this.showPreviousStep()
                this.showPreviousStep()
                this.showPreviousStep()


            })



            // alert(this.registerForm.get('agree').value)

            // alert(this.categorie)
            //
            // alert(JSON.stringify(this.registerForm.value))
        }




        // if (args.step.index == 1 && this.selected_job == 'Guide' && args.direction == 'forward') {
        //     args.step.index = 2;
        //     this.showNextStep();
        // }
        // if (args.step.index == 2 && this.selected_job == 'Guide' && args.direction == 'backward') {
        //     args.step.index = 0;
        //     this.showPreviousStep();
        // }
    }

    isValidTypeBoolean: boolean = true;



    isValidFunctionReturnsObservable(args: StepValidationArgs) {
        return of(true);
    }

    /*

    categorie_change(event){

    }



    get forme_juridique(): any {
        return this.registerForm.get('raison_sociale');
    }
    get email(): any {
        return this.registerForm.get('email');
    }

    get site_web(): any {
        return this.registerForm.get('site_web');
    }
    get telephone(): any {
        return this.registerForm.get('telephone');
    }



    verifyNpi() {
        if(this.npi_promoteur.value !=''){
            this.verifiying = true
            this.registerService.verifyNPI(this.npi_promoteur.value).subscribe((data: any[]) => {
                this.verifiying = false
                console.log(Object.keys(data['data']).length);
                if(data['status'] == 200){
                    if ( Object.keys(data['data']).length === 0 ) {
                        this.failed_npi = true
                        this.valid_npi = false
                    }
                    else {
                        this.failed_npi = false
                        this.valid_npi = true
                    }
                }
          
            }, (error: HttpErrorResponse) => {
                this.failed_npi = true
                this.valid_npi = false
                console.log("Error while retrieving data");
            })
        }
        else{
            this.errors['npi_promoteur'] = this.npi_promoteur.invalid;
        }

    }
    get rccm(): any {
        return this.registerForm.get('rccm');
    }

    get job(): any {
        return this.registerForm.get('job');
    }
    get ifu(): any {
        return this.registerForm.get('ifu');
    }


    get departement(): any {
        return this.registerForm.get('departement');
    }
    get commune(): any {
        return this.registerForm.get('commune');
    }
    get adresse(): any {
        return this.registerForm.get('adresse');
    }
    get code(): any {
        return this.registerForm.get('code');
    }
    get nom_promoteur(): any {
        return this.registerForm.get('nom_promoteur');
    }
    get prenom_promoteur(): any {
        return this.registerForm.get('prenom_promoteur');
    }
    get sexe_promoteur(): any {
        return this.registerForm.get('sexe_promoteur');
    }
    get nationalite_promoteur(): any {
        return this.registerForm.get('nationalite_promoteur');
    }
    get npi_promoteur(): any {
        return this.registerForm.get('npi_promoteur');
    }
    get nom_directeur(): any {
        return this.registerForm.get('nom_directeur');
    }
    get prenom_directeur(): any {
        return this.registerForm.get('prenom_promoteur');
    }
    get nationalite_directeur(): any {
        return this.registerForm.get('nationalite_directeur');
    }
    get sexe_directeur(): any {
        return this.registerForm.get('sexe_directeur');
    }
    get date_de_naissance(): any {
        return this.registerForm.get('date_de_naissance');
    }
    get lieu_de_naissance(): any {
        return this.registerForm.get('lieu_de_naissance');
    }
    get agree(): any {
        return this.registerForm.get('agree');
    }
    get statut(): any {
        return this.registerForm.get('statut');
    }
    get whois(): any {
        return this.registerForm.get('whois');
    }
    get association_groupement(): any {
        return this.registerForm.get('association_groupement');
    }

    */
    async ckeckExite(champ, valeur) {

        let data = new FormData();
        data.append("champ", champ)
        data.append("valeur", valeur);

        if (champ == 'rccm') {
            this.rccm_is_verifying_spinner = true ;
            let res: any = await this.registerService.searchRccm(valeur)
            if (res.data.length > 0) {
                this.used_rccm = false
                this.valid_rccm = true
            } else {
                this.used_rccm = true
            }
            this.rccm_is_verifying_spinner = false ;
            // {
            //     "numeroifu": "0202235903814",
            //     "firstname": "EXDRAS CHRISTIAN",
            //     "numerorccm": "RB/ABC/22 A 50090",
            //     "phone": "+22963516751",
            //     "name": " OPEN ET FILS",
            //     "enterpriseid": 540967,
            //     "email": "godsservice2000@gmail.com",
            //     "lastname": "KINGNIDE"
            // }
            if (res.data.length > 0) {
                this.registerForm.get('ifu').setValue(res.data[0].numeroifu);
                this.registerForm.get('prenom_promoteur').setValue(res.data[0].firstname);
                this.registerForm.get('nom_promoteur').setValue(res.data[0].lastname);
                this.registerForm.get('raison_sociale').setValue(res.data[0].name);
                this.registerForm.get('email').setValue(res.data[0].email)

                const title = document.getElementsByClassName("iti__selected-flag")[0].getAttribute("title");
                const indicatif = title.split(":")[1];
                this.the_current_indicatif = indicatif.replace(/\s/g, '');
                this.registerForm.get('telephone').setValue(res.data[0].phone.substr(4));
            }


            //console.log(res.data[0])
            //  alert(JSON.stringify(res))
        }

        (this.registerService.ckeckExite(data) as unknown as Observable<Object>).subscribe((d: boolean) => {
            if (d) {
                switch (champ) {
                    case 'ifu':

                        this.used_ifu = true;
                        break;
                    case 'telephone':
                        this.used_telephone = true;
                        break;
                    case 'rccm':
                        break;
                    case 'email':
                        this.used_email = true;
                        break;
                    default:
                    // code block
                }
            }
            else {
                switch (champ) {
                    case 'ifu':
                        this.used_ifu = false;
                        break;
                    case 'telephone':
                        this.used_telephone = false;
                        break;
                    case 'rccm':
                        break;
                    case 'email':
                        this.used_email = false;
                        break;
                    default:
                    // code block
                }
            }

        }, error => {

        });

    }

    checkCountrieExist(c: string) {
        for (let index = 0; index < this.countries.length; index++) {
            if (this.countries[index]['name'] === c) {
                return true;
            }
        }
        return false;
    }

}
