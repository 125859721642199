import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Actualite} from '../models/Actualite';

@Injectable({
  providedIn: 'root'
})
export class AdherantService  {

  url: string = environment.backend ;


  constructor(private http: HttpClient) { }

  // Enregistrement d'un element
  // {
  //   headers: {'Content-Type': 'multipart/form-data'}
  // }
  save(data:any ): Observable<Object> {
    return this.http.post(`${this.url}/adherant`, data);
  }

  payMomo(data:any ): Observable<Object> {
    return this.http.post(`${this.url}/addPayMomo`, data);
  }


  updateIsVedete(data:any): Observable<Object> {
    return this.http.post(`${this.url}/updateIsVedete`, data);
  }


  getCotisationByAdherant(id:any): Observable<Object> {
    return this.http.get(`${this.url}/getCotisationByAdherant/${id}`);
  }



}