import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import {FaqComponent, FaqModule } from './components/Faq';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenStorage} from './utils/token.storage';
import {JwtInterceptor} from './utils/JwtInterceptor';
import {ErrorInterceptor} from './utils/ErrorInterceptor';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';


import {MescotisationsModule} from './components/mescotisations';

import {EspaceInvestisseurModule} from './components/EspaceInvestisseur';
import {MesReclamationsModule} from './components/mesReclamations';
import { Mes_eservicesModule } from './components/MesEservices';
import {AdherantModule} from './components/espace-adherant/adherant.module';
import {NavigationModule} from './components/navigation/navigation.module';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';

import {ReshaocModule} from './reshaoc';
import {AccueilModule} from './accueil';
import {ShowActualitesModule} from './show-actualites';
import {ReserverModule} from './components/Reserver';
import {PaysMembreModule} from './pays-membre';
import {ContactModule} from './contact';
import {DocumenthequeModule} from './documentheque';
import {PayerCotisationModule} from './payer-cotisation';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {AdherantPayerCotisationModule} from './components/Adherer-payer-cotisation';

// import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
// import {ShareIconsModule} from 'ngx-sharebuttons/icons';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,


  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    ExamplesModule,
    AppRoutingModule,
    HttpClientModule,

    MescotisationsModule,
    EspaceInvestisseurModule,
    MesReclamationsModule,
    Mes_eservicesModule,
    AdherantModule,
    NavigationModule,

    FaqModule,

    ReactiveFormsModule,
    Ng2TelInputModule,

    AccueilModule,
    ReshaocModule,
    ShowActualitesModule,
    ReserverModule,
    PaysMembreModule,
    ContactModule,
    DocumenthequeModule,
    PayerCotisationModule,
    AdherantPayerCotisationModule
    // HotelEvalModule

// ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    TokenStorage,
    // { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    {provide: LocationStrategy, useClass: HashLocationStrategy}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
