import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Faq} from '../models/faq';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  url: string = environment.backend + '';

  constructor(private http: HttpClient) { }

  // // Enregistrement des pieces
  // saveFile(id: number, piece: Piece): Observable<Object> {
  //   return this.http.post(`${this.url}/save-file/${id}`, piece);
  // }

  token: string = environment.api_token;
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`
  });


  // Enregistrement des reglementation
  save(faq: Faq): Observable<Object> {
    return this.http.post(`${this.url}/save`, faq, {headers: this.headers});
  }

  // Supression des Accord
  delete(faq: Faq): Observable<Object> {
    return this.http.post(`${this.url}/delete`, faq, {headers: this.headers});
  }

  list(): Observable<Object> {

    return this.http.get(`${this.url}/faq`, {headers: this.headers});
  }



  getByJobId(id: number): Observable<Object> {
    return this.http.get(`${this.url}/job/${id}/faq`, {headers: this.headers});
  }

}
