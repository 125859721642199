import { Component, OnInit } from '@angular/core';

import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import {environment} from '../../environments/environment';
import {TokenStorage} from '../utils/token.storage';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(private route: ActivatedRoute,private http: HttpClient , private tokenStorage: TokenStorage) {}
    ssoUrlCallback: string = environment.ssoUrlCallback;

    headers = new HttpHeaders({
        'Authorization':  'Basic bXRjYWxpY2VuY2U6bXRjYWxpY2VuY2U=',
        'Content-Type': 'application/x-www-form-urlencoded'
    });

 code:string


  ngOnInit() {

      // if (this.tokenStorage.getToken() !== null) {
      //
      //     // window.location.href = "https://google.com/about";
      //     window.location.href = environment.backendOffice ;
      // }

      window.location.href = environment.backendOffice ;
      // window.location.href ="https://pprodofficial.service-public.bj/official/login?client_id=mtcalicence&redirect_uri="+this.ssoUrlCallback+"&response_type=code&scope=openid&authError=true";


  }


  /*

  getSsoToken(){



      this.http.post( 'https://pprodofficial.service-public.bj/api/official/token',
          {
              'grant_type':'authorization_code',
              'redirect_uri': environment.ssoUrlCallback,
              'code':this.code

          },
          {headers: this.headers})
          .subscribe(
              (data:any)=>{
                 console.log(data)
              },(error: HttpErrorResponse)=>{
                  console.log("Error while retrieving data");
              }
          )
  }

*/
    

}
