import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NavigationComponent} from './navigation.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        NavigationComponent

    ],
    exports: [
        NavigationComponent
    ]
})
export class NavigationModule {}
