import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EspaceInvestisseurComponent} from './EspaceInvestisseur.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components.module';
import {Ng2TelInputModule} from 'ng2-tel-input';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        FormsModule,  
        ReactiveFormsModule,
        Ng2TelInputModule,

    ],
    declarations: [
        EspaceInvestisseurComponent
    ],
    exports: [
       EspaceInvestisseurComponent
    ]
})
export class EspaceInvestisseurModule {}
